<template>
  <div class="digitalni-otiskovani">
    <HeaderForm/>
    <ThreeColumn/>
    <LogoCloud/>
    <GalleryOverview/>
  </div>
</template>

<script>
import HeaderForm from '@/components/HeaderForm.vue'
import ThreeColumn from '@/components/ThreeColumn.vue'
import LogoCloud from '@/components/LogoCloud.vue'
import GalleryOverview from '@/components/GalleryOverview.vue'
export default {
  name: 'Something',
  components: {
    HeaderForm,
    ThreeColumn,
    LogoCloud,
    GalleryOverview,
  }
}
</script>