<template>
  <div class="about">
    <Header/>
    <HeaderForm/>
    <ContentAlternate/>
    <SignupSection/>
    <SignupInternship/>
    <SignupTips/>
    <TestimonialCard/>
    <ThreeColumn/>
    <LogoCloud/>
    <GalleryOverview/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import HeaderForm from '@/components/HeaderForm.vue'
import ContentAlternate from '@/components/ContentAlternate.vue'
import SignupSection from '@/components/SignupSection.vue'
import SignupInternship from '@/components/SignupInternship.vue'
import SignupTips from '@/components/SignupTips.vue'
import ThreeColumn from '@/components/ThreeColumn.vue'
import LogoCloud from '@/components/LogoCloud.vue'
import TestimonialCard from '@/components/TestimonialCard.vue'
import GalleryOverview from '@/components/GalleryOverview.vue'
export default {
  name: 'Something',
  components: {
    Header,
    HeaderForm,
    ContentAlternate,
    SignupSection,
    SignupInternship,
    SignupTips,
    ThreeColumn,
    LogoCloud,
    TestimonialCard,
    GalleryOverview,
  }
}
</script>