<template>
<!-- HEADER -->
  <div class="bg-gray-100">
    <div class="max-w-7xl mx-auto py-8 px-4 sm:py-12 sm:px-6 lg:px-8">
      <div class="text-left">
        <!-- <h2 class="text-base font-semibold text-indigo-600 tracking-wide uppercase">Pricing</h2> -->
        <h2 class="text-2xl font-extrabold tracking-tight text-red-600 sm:text-4xl">Materiály</h2>
        <p class="max-w-xl mt-2 mr-auto text-lg sm:text-xl text-gray-500">Přehled námi nabízených materiálů</p>
      </div>
    </div>
  </div>
<!-- HEADER END -->


  <div class="relative pb-16 pt-4 bg-white overflow-hidden">
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            <p>K výrobě stomatoprotetických konstrukcí používáme pouze certifikované a biokompatibilní materiály od světově uznávaných a prověřených výrobců jako jsou společnosti ZirkonZahn, Sagemax, BEGO a NextDent.</p>
        </div>
    </div>
  </div>          

<!-- INDIKACE CTA BOX -->
  <div class="bg-red-500">
    <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:py-10 lg:px-8 lg:flex lg:items-center lg:justify-between">
      <h2 class="text-xl font-extrabold tracking-tight text-gray-900 md:text-2xl">
        <span class="block">INDIKACE MATERIÁLŮ</span>
        <span class="block text-gray-100">Usnadněte si výběr správného materiálu</span>
      </h2>
      <div class="mt-8 flex lg:mt-0 lg:flex-shrink-0">
        <div class="inline-flex rounded-md shadow">
          <router-link :to="{ name: 'indikace' }"  class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium text-red-600 bg-gray-50 hover:bg-red-700 hover:text-gray-50 hover:border-2 hover:border-gray-50">
            VÍCE O INDIKACÍCH
          </router-link>
        </div>
      </div>
    </div>
  </div>
<!-- INDIKACE CTA BOX END -->

  <div class="relative pb-16 pt-4 bg-white overflow-hidden">
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            <p class="mt-8 text-xl font-bold text-gray-900 leading-8">SINTROVANÉ MATERIÁLY:</p>
            
            <!-- Item 1 -->
            <p>BEGO Mediloy S-Co</p>
            <ul role="list">
                <li>CoCr materiál ve formě prachu</li>
                <li>v současnosti nejkvalitnější a nejstabilnější dostupný CoCr materiál pro výrobu konstrukcí metodou SLM (laserové sintrování)</li>
                <li>hypoalergenní</li>
                <li>neobsahuje Ni</li>
                <li>certifikace pro dentální užití</li>
                <li>ISO 22674 a ISO 9693-1</li>
                <li>vyvinuto společností BEGO</li>
            </ul>

            <p class="mt-8 text-xl font-bold text-gray-900 leading-8">FRÉZOVANÉ MATERIÁLY:</p>

            <!-- Item 2 -->
            <p class="mt-8 text-xl font-bold text-gray-900 leading-8">NexxZr S (Strong)</p>
            <ul role="list">
                <li>opákní typ zirkonu</li>
                <li>vhodný pro konstrukce korunek i rozsáhlých můstků</li>
                <li>pevnost v ohybu 1370 MPa</li>
            </ul>

            <!-- Item 3 -->
            <p class="mt-8 text-xl font-bold text-gray-900 leading-8">NexxZr T (Translucent)</p>
            <ul role="list">
                <li>vysoce translucentní zirkon (translucence 42%)</li>
                <li>vhodný pro plně anatomické korunky a můstky</li>
                <li>pevnost v ohybu 1270 MPa</li>
            </ul>

            <!-- Item 7 -->
            <p class="mt-8 text-xl font-bold text-gray-900 leading-8">Aidite 3D Pro Zir (estetický vícevrstvý)</p>
            <ul role="list">
                <li>VITA 16 barev + bleech</li>
                <li>Translucence 57% – 43%</li>
                <li>Pevnost v ohybu:  v krčkové části 1050 Mpa , 700 Mpa v okluzi</li>
                <li>Vyrobené ze 100% zirkonového prachu TOSOH ( stejný materiál jako KATANA )</li>
            </ul>

            <!-- Item 8 -->
            <p class="mt-8 text-xl font-bold text-gray-900 leading-8">PMMA Printodent - 3D tisk</p>
            <ul role="list">
                <li>biokompatibiní dlouhodobé provisorium</li>
            </ul>

            <!-- Item 5 -->
            <p class="mt-8 text-xl font-bold text-gray-900 leading-8">PMMA frézovaný</p>
            <ul role="list">
                <li>vhodný pro krátkodobé i dlouhodobé provizoria až na dobu 12 měsíců</li>
                <li>pevnost 106 MPa</li>
                <li>používat eugenol-free cement</li>
                <li>vydrží min. 1 rok</li>
                <li>VITA 16 barev + bleech</li>
            </ul>

            <!-- Item 4 -->
            <p class="mt-8 text-xl font-bold text-gray-900 leading-8">PEEK</p>
            <ul role="list">
                <li>pryskyřice s vysokou odolností vůči plaku a výbornou biokompatibilitou</li>
                <li>výhodou je neomezená sterilizace</li>
                <li>vhodný pro permanentní práce (kapničky teleskopických korunek a attachmenty)</li>
                <li>vhodný pro pacienty trpící alergiemi</li>
            </ul>

            <!-- Item 6 -->
            <p class="mt-8 text-xl font-bold text-gray-900 leading-8">WAX</p>
            <ul role="list">
                <li>100% spalitelný vosk</li>
                <li>možnost výroby inlejí, onlejí, korunek i vícečlenných můstků</li>
                <li>vhodný i pro presované práce</li>
            </ul>

        </div>
    </div>
  </div>
<SignupSection/>
<GalleryOverview/>
</template>
<script>
import SignupSection from '@/components/SignupSection.vue'
import GalleryOverview from '@/components/GalleryOverview.vue'

export default {
    components: {
      SignupSection,
      GalleryOverview,
        }
}
</script>
