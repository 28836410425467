<template>
  <div class="relative py-16 bg-white overflow-hidden">
    <div class="relative px-4 sm:px-6 lg:px-8">
      <div class="text-lg max-w-prose mx-auto">
        <h1>
          <!-- <span class="block text-base text-center text-gray-900 font-semibold tracking-wide uppercase">GDPR</span> -->
          <span class="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-red-600 sm:text-4xl">Všeobecné obchodní podmínky</span>
        </h1>
        <p class="mt-8 text-xl font-bold text-gray-900 leading-8">1. Obecné</p>
      </div>
      <div class="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <p>Objednávky protetických výrobků se provádějí v souladu s obecnými podmínkami obchodního vztahu. Všeobecné obchodní podmínky platí po celou dobu trvání obchodního vztahu, a to i v případě, že platbu provádí třetí strana. Odlišné podmínky vyžadují písemné potvrzení zhotovitele.</p>
        <p>Veškeré smluvní vztahy mezi prodávajícím a kupujícím se řídí právním řádem České republiky, zejména zákonem č. 89/2012 Sb., občanským zákoníkem, v platném znění (dále jen „NOZ“).</p>
        <p class="mt-8 text-xl font-bold text-gray-900 leading-8">2. Ceny</p>
        <p>Veškeré ceny EUROZAHN s.r.o. jsou uvedeny v platném ceníku, který je k dispozici na stránkách
        <router-link :to="{ name: 'Cenik' }" class="text-red-600">
        www.eurozahn.cz
        </router-link>
        </p>
        <p>Zákazník má možnost získat individuální kalkulaci ceny konkrétní zakázky.</p>
        <p>Práva zhotovitele v ceně prostřednictvím individuálního výpočtu cen materiálů (např. pryskyřičné zuby, drahé kovy, komponenty na implantáty) vyhrazena.</p>
        <p class="mt-8 text-xl font-bold text-gray-900 leading-8">3. Dodací lhůta</p>
        <p>Dodací lhůty jsou uvedeny na webu
        <router-link :to="{ name: 'Cenik' }" class="text-red-600">
        www.eurozahn.cz
        </router-link>
             či v platném ceníku.</p>
        <p class="mt-8 text-xl font-bold text-gray-900 leading-8">4. Doprava</p>
        <p>Přeprava směrem od zákazníka i k zákazníkovi je již zahrnuta v rámci ceny za jednotlivé produkty. Jinak je tomu v případě kombinovaných snímatelných prací, které jsou zákazníkovi zasílány vícekrát z důvodu zkoušek s pacientem, zde platí aktuální ceník.</p>
        <p class="mt-8 text-xl font-bold text-gray-900 leading-8">5. Odpovědnost</p>
        <p>Klient je povinen okamžitě zkontrolovat práci po přijetí. Reklamace zákazníka musí být písemně oznámena dodavateli bez prodlení. V případě reklamace zákazník poskytne pracovní modely a další nezbytné součásti dodávky potřebné pro následné vylepšení či kompletní náhradní dodávku.</p>
        <p>Záruka se vztahuje na defekty, které vznikly na straně dodavatele anebo byla-li zakázka dodána dopravcem v poničeném stavu; dodavatel si vyhrazuje právo rozhodnout o jaký druh závady se jedná. V případě neschopnosti dodavatele odstranit vadu nebo dodat zakázku v souladu s podmínkami spolupráce, zákazník má právo žádat o slevu či odstoupit od smlouvy, resp. závazné objednávky.</p>
        <p>Nároky zákazníka na náhradu škody jsou vyloučeny, pokud jsou založeny na hrubé nedbalosti zacházení s výrobkem či poručení ze strany dodavatele, nebo úmyslným nebo hrubě nedbalým porušením smlouvy ze strany zákonného zástupce nebo zástupce zhotovitele. To se nevztahuje na škody způsobené zraněním života, těla nebo zdraví.</p>
        <p class="mt-8 text-xl font-bold text-gray-900 leading-8">6. Platby</p>
        <p>Všechny faktury jsou splatné do 14 kalendářních dnů po datu jejich vystavení, přičemž platba bude považována za uhrazenou po připsání příslušné částky na bankovní účet dodavatele.</p>
        <p>V případě prodlení zákazníka s úhradou platby dodavatele zákazníka na prodlení s platbou upozorní, a to písemně nebo elektronicky, a stanoví mu náhradní lhůtu plnění, která nesmí být kratší než 10 dní ode dne doručení upozornění. Po marném uplynutí náhradní lhůty je dodavatele oprávněn účtovat smluvní pokutu ve výši 0,1 % z dlužné částky za každý i započatý den prodlení, přičemž prodlení se počítá ode dne původního data splatnosti.</p>
        <p class="mt-8 text-xl font-bold text-gray-900 leading-8">7. Výhrada vlastnictví</p>
        <p>Veškeré doručené práce podléhají zachování vlastnického práva až do úplného zaplacení všech nároků z obchodního vztahu.</p>
    </div>
    </div>
  </div>
</template>
