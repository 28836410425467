<template>
  <div>
    <h1>Děkujeme za Váš zájem o registraci k odběru newsletterů!</h1>
    <router-link to="/">
      <button>Zpět</button>
    </router-link>
  </div>
</template>

<script>
  export default {
    name: "SubmissionSuccess"
  }
</script>