<template>
<!-- HEADER -->
  <div class="bg-gray-100">
    <div class="max-w-7xl mx-auto py-8 px-4 sm:py-12 sm:px-6 lg:px-8">
      <div class="text-left">
        <!-- <h2 class="text-base font-semibold text-indigo-600 tracking-wide uppercase">Pricing</h2> -->
        <h2 class="text-2xl font-extrabold tracking-tight text-red-600 sm:text-4xl">Fakturace & formality</h2>
        <p class="max-w-xl mt-2 mr-auto text-lg sm:text-xl text-gray-500"></p>
      </div>
    </div>
  </div>
<!-- HEADER END -->

  <div class="relative pb-16 pt-4 bg-white overflow-hidden">
    <div class="relative px-4 sm:px-6 lg:px-8">
        <!-- <div class="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            <p>K výrobě stomatoprotetických konstrukcí používáme pouze certifikované a biokompatibilní materiály od světově uznávaných a prověřených výrobců jako jsou společnosti ZirkonZahn, Sagemax, BEGO a NextDent.</p>
            <p class="mt-8 text-xl font-bold text-gray-900 leading-8">SINTROVANÉ MATERIÁLY:</p>
            <p>BEGO Mediloy S-Co</p>
            <ul role="list">
                <li>CoCr materiál ve formě prachu</li>
                <li>v současnosti nejkvalitnější a nejstabilnější dostupný CoCr materiál pro výrobu konstrukcí metodou SLM (laserové sintrování)</li>
                <li>hypoalergenní</li>
                <li>neobsahuje Ni</li>
                <li>certifikace pro dentální užití</li>
                <li>ISO 22674 a ISO 9693-1</li>
                <li>vyvinuto společností BEGO</li>
            </ul>
            <p class="mt-8 text-xl font-bold text-gray-900 leading-8">FRÉZOVANÉ MATERIÁLY:</p>
            <p class="mt-8 text-xl font-bold text-gray-900 leading-8">NexxZr S (Strong)</p>
            <ul role="list">
                <li>opákní typ zirkonu</li>
                <li>vhodný pro konstrukce korunek i rozsáhlých můstků</li>
                <li>pevnost v ohybu 1370 MPa</li>
            </ul>
            <p class="mt-8 text-xl font-bold text-gray-900 leading-8">NexxZr T (Translucent)</p>
            <ul role="list">
                <li>vysoce translucentní zirkon (translucence 42%)</li>
                <li>vhodný pro plně anatomické korunky a můstky</li>
                <li>pevnost v ohybu 1270 MPa</li>
            </ul>
            <p class="mt-8 text-xl font-bold text-gray-900 leading-8">TECNO Med ZirkonZahn (PEEK)</p>
            <ul role="list">
                <li>pryskyřice s vysokou odolností vůči plaku a výbornou biokompatibilitou</li>
                <li>výhodou je neomezená sterilizace</li>
                <li>vhodný pro permanentní práce (kapničky teleskopických korunek a attachmenty)</li>
                <li>vhodný pro pacienty trpící alergiemi</li>
            </ul>
            <p class="mt-8 text-xl font-bold text-gray-900 leading-8">Temp Premium PMMA ZirkonZahn</p>
            <ul role="list">
                <li>vhodný pro krátkodobé i dlouhodobé provizoria až na dobu 12 měsíců</li>
                <li>maximální tenkost stěn u modelace – cervikálně 0,7 mm</li>
                <li>maximální tenkost stěn u modelace – okluzálně – 1,2 mm</li>
                <li>pevnost 106 MPa</li>
                <li>možnosti odstínů barev: A1-B1, A2-A3, C2-C3</li>
                <li>používat eugenol-free cement</li>
            </ul>
            <p class="mt-8 text-xl font-bold text-gray-900 leading-8">WAX ZirkonZahn</p>
            <ul role="list">
                <li>100% spalitelný vosk</li>
                <li>možnost výroby inlejí, onlejí, korunek i vícečlenných můstků</li>
                <li>vhodný i pro presované práce</li>
            </ul>
        </div> -->
    </div>
  </div>
</template>