<template>
  <div class="bg-red-600">
    <div class="max-w-7xl mx-auto py-8 px-4 sm:py-10 sm:px-6 lg:px-8">
      <div class="text-center">
        <!-- <h2 class="text-base font-semibold text-red-600 tracking-wide uppercase">Pricing</h2> -->
        <p class="mt-1 text-2xl font-medium text-white sm:text-4xl sm:tracking-tight lg:text-5xl">5 tipů na zdokonalení Vašeho digitálního otiskování</p>
        <p class="max-w-xl mt-5 mx-auto text-lg font-light text-gray-100">Specializujeme se na fixní protetiku. Práce na implantátech jsou pro nás rutina. Digitální skenery používáme již téměř 11 let a za tu dobu jsme viděli již více než 62.000 skenů. I proto víme, jak má vypadat dobrý podklad z vašeho intraorálního pomocníka. Chcete se dozvědět, jak zlepšit vaše digitální otiskování?</p>
        <form method="post" data-netlify="true" netlify-honeypot="bot-field" name="ios-tipy" action="#" class="mt-10 sm:mx-auto sm:max-w-lg sm:flex">
          <div class="min-w-0 flex-1">
              <p class="hidden">
                <label>Don’t fill this out if you’re human: <input name="bot-field" /></label>
              </p>
              <input type="hidden" name="form-name" value="ios-tipy" />
            <label for="cta-email" class="sr-only">Váš email</label>
            <input id="cta-email" type="email" autocomplete="email" required="" name="email-address" class="block w-full border border-transparent rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-red-600" placeholder="Zadejte Váš email..." />
          </div>
          <div class="mt-4 sm:mt-0 sm:ml-3">
            <button type="submit" class="uppercase block w-full rounded-md border border-transparent px-5 py-3 bg-gray-200 text-base font-medium text-red-600 shadow hover:bg-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-red-600 sm:px-10">CHCI Lépe skenovat</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
