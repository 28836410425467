<!-- BANNER COMPONENT -->
<template>


<!-- BANNER -->
  <Disclosure defaultOpen v-slot="{ open, close }" class="fixed bottom-0 inset-x-0 pb-2 sm:pb-5 z-50">
    <div>
    <!-- <TransitionRoot
    appear
    :show="isShowing"
    enter="hidden"
    enter-from="opacity-0"
    enter-to="opacity-100"
    leave="transition-opacity duration-150"
    leave-from="opacity-100"
    leave-to="opacity-0"
    > -->
      <!-- CLOSED STATE BUTTON -->
      <div v-show="close" :class="[open ? 'hidden' : 'inline']">
        <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
          <div class="p-2 sm:p-3">
            <div class="flex items-center justify-between flex-wrap">
              <div>
                <DisclosureButton type="button" class="text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-600">
                  <span class="sr-only">Otevřít nabídku</span>
                  <span class="flex p-2 rounded-lg bg-red-600 hover:bg-white hover:border-red-600 border-2 border-transparent text-white hover:text-red-600 hover:border-2">
                    <BellIcon class="h-6 w-6" aria-hidden="true" />
                  </span>
                </DisclosureButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- CLOSED STATE BUTTON END -->

      <!-- DISCLOSURE BANNER PANEL -->
      <transition
      enter-class="transition transform duration-300 ease-out"
      enter-active-class="transition transform duration-300 ease-out"
      enter-from-class="translate-x-0 opacity-0"
      enter-to-class="translate-x-0 opacity-100"
      leave-class="transition transform duration-300 ease-in"
      leave-active-class="transition transform duration-300 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
      >
      <div v-show="open">
      <DisclosurePanel class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div class="p-2 rounded-lg bg-red-600 shadow-lg sm:p-3">
          <div class="flex items-center justify-between flex-wrap">
            <div class="w-0 flex-1 flex items-center">
              <DisclosureButton>
                <span class="flex p-2 rounded-lg bg-red-800">
                  <BellIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </span>
              </DisclosureButton>
              <p class="ml-3 font-medium text-white truncate">
                <span class="inline sm:hidden">
                  Můžeme Vám poradit?
                </span>
                <span class="hidden sm:inline md:hidden">
                  Můžeme Vám s něčím poradit?
                </span>
                <span class="hidden md:inline lg:hidden">
                  Dobrý den, můžeme Vám s něčím poradit?
                </span>
                <span class="hidden lg:inline xl:hidden">
                  Dobrý den, těší nás, že jste navštívil/a naše stránky. <span class="font-bold">Můžeme Vám s něčím poradit?</span>
                </span>
                <span class="hidden xl:inline">
                  Dobrý den, těší nás, že už jste několikrát navštívil/a naše stránky. <span class="font-bold">Můžeme Vám s něčím poradit?</span>
                </span>
              </p>
            </div>
            <div class="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
              <a href="tel:+420 725 433 894" class="flex items-center justify-center px-4 py-2 border-2 rounded-md border-transparent shadow-sm text-sm font-medium text-red-600 bg-white hover:text-white hover:bg-red-500 hover:border-white">
                +420 725 433 894
              </a>
            </div>
            <div class="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
              <DisclosureButton type="button" class="-mr-1 flex p-2 rounded-md hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-white">
                <span class="sr-only">Dismiss</span>
                <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
              </DisclosureButton>
            </div>
          </div>
        </div>
      </DisclosurePanel>
      </div>
      </transition>
      <!-- DISCLOSURE BANNER PANEL END -->
      <!-- </TransitionRoot> -->
    </div>
  </Disclosure>
</template>

<script>
// import { ref } from 'vue'
// import { TransitionRoot, Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { BellIcon, XIcon } from '@heroicons/vue/outline'

export default {
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    BellIcon,
    XIcon,
  },
  // setup() {
  //   const isShowing = ref(true)

  //   return {
  //     isShowing,
  //   }
  // },
}
</script>
