<template>
  <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
      <div class="text-lg max-w-prose mx-auto pb-6">
        <h1>
          <!-- <span class="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">inovativní a funkční zubní náhrady</span> -->
          <span class="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-red-600 sm:text-4xl">Inovativní a funkční zubní náhrady</span>
        </h1>
        <p class="mt-8 text-xl text-gray-500 leading-8">Zdraví a komfort pacienta je pro nás zavazující. Proto dodáváme zubní náhrady, které splňují nejpřísnější měřítka, a přitom za přijatelnou cenu. Za to ručíme 5letou garancí na naši fixní protetiku, jelikož jsme přesvědčeni o kvalitě našich výrobků. Naše kontrola jakosti stojí na striktních kritériích. Pokud si nejsme jisti výsledkem hotové práce, nemůžeme ji předat dále do ordinace. Používáme pouze CE certifikované materiály, které navíc splňují ISO normu. </p>
      </div>
    <div class="bg-gray-50 overflow-hidden">
    <div class="relative max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <div class="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
        <div class="lg:col-span-1">
          <h2 class="text-3xl font-extrabold tracking-tight text-red-600 sm:text-4xl">
            Nabídka produktů
          </h2>
        </div>
        <dl class="mt-10 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:mt-0 lg:col-span-2">
          <div v-for="feature in features" :key="feature.name">
            <dt>
              <!-- <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                <component :is="feature.icon" class="h-6 w-6" aria-hidden="true" />
              </div> -->
              <p class="mt-5 text-lg leading-6 font-medium text-gray-900">{{ feature.name }}</p>
            </dt>
            <dd class="mt-2 text-base text-gray-500">
            <div v-for="item in feature.description" :key="item">
            {{ item }}
            </div>
        </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
      <div class="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <!-- <p>Faucibus commodo massa rhoncus, volutpat. <strong>Dignissim</strong> sed <strong>eget risus enim</strong>. Mattis mauris semper sed amet vitae sed turpis id. Id dolor praesent donec est. Odio penatibus risus viverra tellus varius sit neque erat velit. Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. <a href="#">Mattis mauris semper</a> sed amet vitae sed turpis id.</p>
        <ul role="list">
          <li>Quis elit egestas venenatis mattis dignissim.</li>
          <li>Cras cras lobortis vitae vivamus ultricies facilisis tempus.</li>
          <li>Orci in sit morbi dignissim metus diam arcu pretium.</li>
        </ul>
        <p>Quis semper vulputate aliquam venenatis egestas sagittis quisque orci. Donec commodo sit viverra aliquam porttitor ultrices gravida eu. Tincidunt leo, elementum mattis elementum ut nisl, justo, amet, mattis. Nunc purus, diam commodo tincidunt turpis. Amet, duis sed elit interdum dignissim.</p>
         -->
        <h2>Kvalitní zubní náhrada i pro Vaše pacienty?</h2>
        <p>Pokud se nehodláte spokojit s průměrem a chcete svým pacientům nabídnout protetiku vyšší kvality, víte, že musí nejen adekvátně vypadat a perfektně sedět, ale také musí být pacientovi přirozená. Ideálně jako původní zuby. Zuby jsou pro spokojený plnohodnotný život důležité. Pro tyto účely vám může být zubní laboratoř EUROZAHN spolehlivým partnerem.</p>
        <br>
        <br>
        <!-- <blockquote>
          <p>Pacient musí být s novým chrupem naprosto spokojený a nesmí cítit rozdíl. A už vůbec ne v kvalitě. To by mělo být cílem každého zubního lékaře. Proto je naším mottem přinášet stomatologům jen spokojené pacienty. A v neposlední řadě i finanční podíl na zisku.</p>
        </blockquote> -->
        <p>Fixní zubní náhrady z laboratoře EUROZAHN se vyrábějí pomocí digitálních technologií. S příchodem digitalizace a 3D tisku se světu ve 21. století otevřely dosud naprosto nevídané možnosti. My se snažíme být lídrem této oblasti v tomto oboru. Veškerý sortiment zubních náhrad frézujeme, tiskneme či sintrujeme laserem. Při výrobě spojujeme špičkové moderní technologie s šikovností a zkušeností našich zubních techniků, kteří jsou pravidelně školeni a vzděláváni. 
Kompletní výrobní proces je zaštítěný našimi interními standardy i normou ISO 9001 a podléhá náročné vstupní, výstupní a výrobní kontrole. Proto si můžeme dovolit poskytovat na námi vyrobenou protetiku pětiletou záruku.</p>
        <figure>
          <img class="w-full rounded-lg" src="@/assets/img/components/crown.jpg" alt="" width="1310" height="873" />
          <!-- <figcaption>Sagittis scelerisque nulla cursus in enim consectetur quam.</figcaption> -->
        </figure>
        <!-- <h2>Mattis mauris semper sed amet</h2>
        <p>Purus morbi dignissim senectus mattis <a href="#">adipiscing</a>. Amet, massa quam varius orci dapibus volutpat cras. In amet eu ridiculus leo sodales cursus tristique. Tincidunt sed tempus ut viverra ridiculus non molestie. Gravida quis fringilla amet eget dui tempor dignissim. Facilisis auctor venenatis varius nunc, congue erat ac. Cras fermentum convallis quam.</p>
        <p>Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet vitae sed turpis id. Id dolor praesent donec est. Odio penatibus risus viverra tellus varius sit neque erat velit.</p> -->
      </div>
    </div>
  </div>
</template>

<script>
const features = [
  {
    name: 'Korunky a můstky',
    description: ['Keramika','Zirkon','Metalokeramika','PMMA','Kompozit'],
    // icon: GlobeAltIcon,
  },
  {
    name: 'Implantáty',
    description: ['Celokeramika', 'Metalokeramika', 'Zirkon','Kompozit'],
    // icon: ScaleIcon,
  },
  {
    name: 'Ostatní',
    description: ['Nákusná dlaha', 'Fólie k bělení', 'Chránič zubů'],
    // icon: LightningBoltIcon,
  },
//   {
//     name: 'Indikace materiálů',
//     description:
//       ['Usnadněte si výběr správného materiálu s naším přehledným průvodcem.'],
//     // icon: MailIcon,
//     button: 'PRŮVODCE INDIKACEMI',
//   },
]

export default {
  setup() {
    return {
        features,
    }
  },
}
</script>