<template>
  <Popover class="relative bg-white">
    <div class="max-w-7xl mx-auto px-4 sm:px-6">
      <div
        class="
          flex
          justify-between
          items-center
          border-b-2 border-gray-100
          py-6
          md:justify-start md:space-x-10
        "
      >
        <div class="flex justify-start md:w-0 md:flex-1">
          <router-link :to="{ name: 'Homepage' }">
            <span class="sr-only">Eurozahn</span>
            <img class="h-8 w-auto sm:h-10" src="@/assets/logo.png" alt="" />
          </router-link>
        </div>
        <div class="-mr-2 -my-2 lg:hidden">
          <PopoverButton
            class="
              bg-white
              rounded-md
              p-2
              inline-flex
              items-center
              justify-center
              text-gray-400
              hover:text-gray-500 hover:bg-gray-100
              focus:outline-none
              focus:ring-2
              focus:ring-inset
              focus:ring-red-600
            "
          >
            <span class="sr-only">Open menu</span>
            <MenuIcon class="h-6 w-6" aria-hidden="true" />
          </PopoverButton>
        </div>
        <PopoverGroup as="nav" class="hidden lg:flex space-x-10">
          <!-- <Popover class="relative" v-slot="{ open }">
            <PopoverButton :class="[open ? 'text-gray-900' : 'text-gray-500', 'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
              <span>Solutions</span>
              <ChevronDownIcon :class="[open ? 'text-gray-600' : 'text-gray-400', 'ml-2 h-5 w-5 group-hover:text-gray-500']" aria-hidden="true" />
            </PopoverButton>

            <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
              <PopoverPanel class="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                    <a v-for="item in solutions" :key="item.name" :href="item.href" class="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50">
                      <component :is="item.icon" class="flex-shrink-0 h-6 w-6 text-indigo-600" aria-hidden="true" />
                      <div class="ml-4">
                        <p class="text-base font-medium text-gray-900">
                          {{ item.name }}
                        </p>
                        <p class="mt-1 text-sm text-gray-500">
                          {{ item.description }}
                        </p>
                      </div>
                    </a>
                  </div>
                  <div class="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                    <div v-for="item in callsToAction" :key="item.name" class="flow-root">
                      <a :href="item.href" class="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100">
                        <component :is="item.icon" class="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                        <span class="ml-3">{{ item.name }}</span>
                      </a>
                    </div>
                  </div>
                </div>
              </PopoverPanel>
            </transition>
          </Popover> -->
          <!-- <router-link :to="{ name: 'About' }" class="text-base font-medium text-gray-500 hover:text-gray-900">About</router-link> -->
          <!-- <router-link :to="{ name: 'DigitalniOtiskovani' }" class="text-base uppercase font-medium text-gray-500 hover:text-red-600">Digitalni Otiskovani</router-link> -->
          <!-- NAVRHNOUT LAYOUT  -->
          <!-- ZUBNI NAHRADY -->
          <!-- CENIK -->
          <!-- VYROBA -->
          <!-- NAS TEAM -->
          <!-- KONTAKT -->
          <!-- <router-link :to="{ name: 'VolnePozice' }" class="text-base uppercase font-medium text-gray-400 hover:text-red-600">Volne Pozice</router-link> -->
          <router-link
            :to="{ name: 'Nahrady' }"
            class="
              text-base
              uppercase
              font-medium
              text-gray-500
              hover:text-red-600
            "
            >ZUBNÍ NÁHRADY</router-link
          >
          <router-link
            :to="{ name: 'Vyroba' }"
            class="
              text-base
              uppercase
              font-medium
              text-gray-500
              hover:text-red-600
            "
            >VÝROBA</router-link
          >
          <router-link
            :to="{ name: 'Cenik' }"
            class="
              text-base
              uppercase
              font-medium
              text-gray-500
              hover:text-red-600
            "
            >CENÍK</router-link
          >
          <router-link
            :to="{ name: 'NasTeam' }"
            class="
              text-base
              uppercase
              font-medium
              text-gray-500
              hover:text-red-600
            "
            >NÁŠ TEAM</router-link
          >
          <router-link
            :to="{ name: 'Kontakt' }"
            class="
              text-base
              uppercase
              font-medium
              text-gray-500
              hover:text-red-600
            "
            >KONTAKT</router-link
          >
          <a
            href="https://blog.eurozahn.cz"
            class="
              text-base
              uppercase
              font-medium
              text-gray-500
              hover:text-red-600
            "
            >BLOG</a
          >
          <!-- <Popover class="relative" v-slot="{ open }">
            <PopoverButton :class="[open ? 'text-gray-900' : 'text-gray-500', 'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
              <span>More</span>
              <ChevronDownIcon :class="[open ? 'text-gray-600' : 'text-gray-400', 'ml-2 h-5 w-5 group-hover:text-gray-500']" aria-hidden="true" />
            </PopoverButton>

            <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
              <PopoverPanel class="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0">
                <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div class="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                    <a v-for="item in resources" :key="item.name" :href="item.href" class="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50">
                      <component :is="item.icon" class="flex-shrink-0 h-6 w-6 text-indigo-600" aria-hidden="true" />
                      <div class="ml-4">
                        <p class="text-base font-medium text-gray-900">
                          {{ item.name }}
                        </p>
                        <p class="mt-1 text-sm text-gray-500">
                          {{ item.description }}
                        </p>
                      </div>
                    </a>
                  </div>
                  <div class="px-5 py-5 bg-gray-50 sm:px-8 sm:py-8">
                    <div>
                      <h3 class="text-sm tracking-wide font-medium text-gray-500 uppercase">
                        Recent Posts
                      </h3>
                      <ul class="mt-4 space-y-4">
                        <li v-for="post in recentPosts" :key="post.id" class="text-base truncate">
                          <a :href="post.href" class="font-medium text-gray-900 hover:text-gray-700">
                            {{ post.name }}
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="mt-5 text-sm">
                      <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500"> View all posts <span aria-hidden="true">&rarr;</span></a>
                    </div>
                  </div>
                </div>
              </PopoverPanel>
            </transition>
          </Popover> -->
        </PopoverGroup>
        <div
          class="group hidden lg:flex items-center justify-end md:flex-1 lg:w-0"
        >
          <!-- <a href="#" class="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900">
            Sign in
          </a> -->
          <a
            href="tel:+420 725 433 894"
            class="
              ml-8
              whitespace-nowrap
              inline-flex
              items-center
              justify-center
              px-4
              py-2
              border-2 border-red-600
              square-md
              shadow-sm
              text-base
              font-medium
              text-red-600
              bg-white
              hover:bg-red-600
              group-hover:text-white
            "
          >
            <PhoneIcon
              class="mr-3 h-6 w-6 text-red-600 group-hover:text-white"
              aria-hidden="true"
            />
            +420 725 433 894
          </a>
        </div>
      </div>
    </div>

    <transition
      enter-active-class="duration-200 ease-out"
      enter-from-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-100 ease-in"
      leave-from-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <PopoverPanel
        focus
        class="
          absolute
          top-0
          inset-x-0
          p-2
          transition
          transform
          origin-top-right
          lg:hidden
          z-50
        "
      >
        <div
          class="
            rounded-lg
            shadow-lg
            ring-1 ring-black ring-opacity-5
            bg-white
            divide-y-2 divide-gray-50
          "
        >
          <div class="pt-5 pb-6 px-5">
            <div class="flex items-center justify-between">
              <div>
                <router-link :to="{ name: 'Homepage' }">
                  <span class="sr-only">Eurozahn</span>
                  <img
                    class="h-8 w-auto sm:h-10"
                    src="@/assets/logo.png"
                    alt=""
                  />
                </router-link>
              </div>
              <div class="-mr-2">
                <PopoverButton
                  class="
                    bg-white
                    rounded-md
                    p-2
                    inline-flex
                    items-center
                    justify-center
                    text-gray-400
                    hover:text-gray-500 hover:bg-gray-100
                    focus:outline-none
                    focus:ring-2
                    focus:ring-inset
                    focus:ring-indigo-500
                  "
                >
                  <span class="sr-only">Close menu</span>
                  <XIcon class="h-6 w-6" aria-hidden="true" />
                </PopoverButton>
              </div>
            </div>
            <div class="mt-6">
              <nav class="grid gap-y-8">
                <router-link
                  v-for="item in solutions"
                  :key="item.name"
                  :to="item.href"
                  class="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                >
                  <component
                    :is="item.icon"
                    class="flex-shrink-0 h-6 w-6 text-red-600"
                    aria-hidden="true"
                  />
                  <span
                    class="ml-3 text-base font-medium text-gray-900 uppercase"
                  >
                    {{ item.name }}
                  </span>
                </router-link>
                <a
                  href="https://blog.eurozahn.cz"
                  class="
                    text-base
                    uppercase
                    font-medium
                    text-gray-500
                    hover:text-red-600
                  "
                  >BLOG</a
                >
              </nav>
            </div>
          </div>
          <div class="py-6 px-5 space-y-6">
            <!-- <div class="grid grid-cols-2 gap-y-4 gap-x-8">
              <a href="#" class="text-base font-medium text-gray-900 hover:text-gray-700">
                Pricing
              </a>

              <a href="#" class="text-base font-medium text-gray-900 hover:text-gray-700">
                Docs
              </a>
              <a v-for="item in resources" :key="item.name" :href="item.href" class="text-base font-medium text-gray-900 hover:text-gray-700">
                {{ item.name }}
              </a>
            </div> -->
            <div class="group">
              <a
                href="tel:+420 725 433 894"
                class="
                  w-full
                  flex
                  items-center
                  justify-center
                  px-4
                  py-2
                  border border-transparent
                  rounded-md
                  shadow-sm
                  text-base
                  font-medium
                  border border-red-600
                  square-md
                  shadow-sm
                  text-base
                  font-medium
                  text-red-600
                  bg-white
                  hover:bg-red-600
                  group-hover:text-white
                "
              >
                <PhoneIcon
                  class="mr-3 h-6 w-6 text-red-600 group-hover:text-white"
                  aria-hidden="true"
                />
                +420 725 433 894
              </a>
            </div>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
  <router-view />
  <Footer />
</template>

<script>
import Footer from '@/components/Footer.vue'
import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from '@headlessui/vue'
import {
  DesktopComputerIcon,
  BookmarkAltIcon,
  CalendarIcon,
  MenuIcon,
  PhoneIcon,
  PlayIcon,
  ShieldCheckIcon,
  SupportIcon,
  XIcon,
  UserGroupIcon,
  OfficeBuildingIcon,
  PrinterIcon,
  CashIcon,
} from '@heroicons/vue/outline'
import { ChevronDownIcon } from '@heroicons/vue/solid'

const solutions = [
  {
    name: 'ZUBNÍ NÁHRADY',
    description:
      'Get a better understanding of where your traffic is coming from.',
    href: 'nahrady',
    icon: DesktopComputerIcon,
    external: false,
  },
  {
    name: 'VÝROBA',
    description: 'Speak directly to your customers in a more meaningful way.',
    href: 'vyroba',
    icon: PrinterIcon,
    external: false,
  },
  {
    name: 'CENÍK',
    description: "Your customers' data will be safe and secure.",
    href: 'cenik',
    icon: CashIcon,
    external: false,
  },
  {
    name: 'Náš Team',
    description: "Your customers' data will be safe and secure.",
    href: 'nas-team',
    icon: UserGroupIcon,
    external: false,
  },
  {
    name: 'Kontakt',
    description: "Connect with third-party tools that you're already using.",
    href: 'kontakt',
    icon: OfficeBuildingIcon,
    external: false,
  },
]
const callsToAction = [
  { name: 'Watch Demo', href: '#', icon: PlayIcon },
  { name: 'Contact Sales', href: '#', icon: PhoneIcon },
]
const resources = [
  {
    name: 'Help Center',
    description:
      'Get all of your questions answered in our forums or contact support.',
    href: '#',
    icon: SupportIcon,
  },
  {
    name: 'Guides',
    description:
      'Learn how to maximize our platform to get the most out of it.',
    href: '#',
    icon: BookmarkAltIcon,
  },
  {
    name: 'Events',
    description:
      'See what meet-ups and other events we might be planning near you.',
    href: '#',
    icon: CalendarIcon,
  },
  {
    name: 'Security',
    description: 'Understand how we take your privacy seriously.',
    href: '#',
    icon: ShieldCheckIcon,
  },
]
const recentPosts = [
  { id: 1, name: 'Boost your conversion rate', href: '#' },
  {
    id: 2,
    name: 'How to use search engine optimization to drive traffic to your site',
    href: '#',
  },
  { id: 3, name: 'Improve your customer experience', href: '#' },
]

export default {
  components: {
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    PrinterIcon,
    PhoneIcon,
    DesktopComputerIcon,
    OfficeBuildingIcon,
    ChevronDownIcon,
    UserGroupIcon,
    MenuIcon,
    XIcon,
    Footer,
  },
  setup() {
    return {
      solutions,
      callsToAction,
      resources,
      recentPosts,
    }
  },
}
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

h4 {
  font-size: 20px;
} */
</style>
