import { createRouter, createWebHistory } from 'vue-router'
import Homepage from '../views/Homepage.vue'
import EventDetails from '../views/EventDetails'
import About from '../views/About.vue'
import DigitalniOtiskovani from '../views/DigitalniOtiskovani.vue'
import VolnePozice from '../views/VolnePozice.vue'
import Vyroba from '../views/Vyroba.vue'
import Cenik from '../views/Cenik.vue'
import NasTeam from '../views/NasTeam.vue'
import Kontakt from '../views/Kontakt.vue'
import LoginApp from '../views/LoginApp.vue'
import Nahrady from '../views/Nahrady.vue'
import ObchodniPodminky from '../views/ObchodniPodminky.vue'
import Materialy from '../views/Materialy.vue'
import Indikace from '../views/Indikace.vue'
import ZakazkyInformace from '../views/ZakazkyInformace.vue'
import Fakturace from '../views/Fakturace.vue'
import Gdpr from '../views/Gdpr.vue'
import SubmissionSuccess from '../views/SubmissionSuccess.vue'
import SubmissionFail from '../views/SubmissionFail.vue'

const routes = [
  {
    path: '/',
    name: 'Homepage',
    component: Homepage,
  },
  {
    path: '/event/:id',
    name: 'EventDetails',
    props: true,
    component: EventDetails,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },{
    path: '/digitalni-otiskovani',
    name: 'DigitalniOtiskovani',
    component: DigitalniOtiskovani,
  },{
    path: '/volne-pozice',
    name: 'VolnePozice',
    component: VolnePozice,
  },{
    path: '/vyroba',
    name: 'Vyroba',
    component: Vyroba,
  },{
    path: '/materialy',
    name: 'Materialy',
    component: Materialy,
  },{
    path: '/cenik',
    name: 'Cenik',
    component: Cenik,
  },{
    path: '/nas-team',
    name: 'NasTeam',
    component: NasTeam,
  },{
    path: '/kontakt',
    name: 'Kontakt',
    component: Kontakt,
  },{
    path: '/log-in',
    name: 'LoginApp',
    component: LoginApp,
  },{
    path: '/nahrady',
    name: 'Nahrady',
    component: Nahrady,
  },{
    path: '/obchodni-podminky',
    name: 'ObchodniPodminky',
    component: ObchodniPodminky,
  },{
    path: '/gdpr',
    name: 'Gdpr',
    component: Gdpr,
  },
  {
    path: '/thanks',
    name: 'success',
    component: SubmissionSuccess
  },
  {
    path: '/404',
    name: 'fail',
    component: SubmissionFail
  },
  {
    path: '/indikace',
    name: 'indikace',
    component: Indikace
  },
  {
    path: '/zakazky-informace',
    name: 'zakazky-informace',
    component: ZakazkyInformace
  },
  {
    path: '/fakturace',
    name: 'fakturace',
    component: Fakturace
  }
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  //   if (savedPosition) {
  //     return savedPosition
  //   } else {
  //     return { top: 0 }
  //   }
  },
})

export default router
