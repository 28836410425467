<template>
  <!-- <div class="bg-gray-200">
    <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      <h2 class="inline text-3xl font-extrabold tracking-tight text-gray-900 sm:block sm:text-4xl">
        Efektivní a zisková ordinace.
      </h2>
      <p class="inline text-3xl font-extrabold tracking-tight text-red-600 sm:block sm:text-4xl">Poradíme Vám jak na to!</p>
      <form class="mt-8 sm:flex">
        <label for="email-address" class="sr-only">Email address</label>
        <input id="email-address" name="email" type="email" autocomplete="email" required="" class="w-full px-5 py-3 placeholder-gray-500 focus:ring-red-500 focus:border-red-500 sm:max-w-xs border-gray-300" placeholder="Zadejte Váš email..." />
        <div class="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
          <button type="submit" class="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
            CHCI PORADIT
          </button>
        </div>
      </form>
    </div>
  </div> -->

        <!-- Hero card -->
        <div class="relative">
          <div class="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
          <div class="">
            <div class="relative shadow-xl sm:overflow-hidden">
              <div class="absolute inset-0">
                <img class="h-full w-full object-cover object-center" src="@/assets/img/components/internship.jpg" alt="People working on laptops" />
                <div class="absolute inset-0 bg-red-50 mix-blend-multiply" />
              </div>
            <div class="flex flex-col lg:flex-row">
              <div class="relative max-w-3xl lg:mx-auto pb-2 pt-12 lg:py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                <h2 class="inline text-3xl font-extrabold tracking-tight text-gray-100 sm:block sm:text-4xl">
                  Studujete a hledáte praxi?
                </h2>
                <p class="inline text-lg font-bold tracking-tight text-red-600 sm:block sm:text-xl">
                  Získáte neocenitelné zkušenosti při práci s nejmodernějšími technologiemi a vyzkoušíte všechny výhody práce v plně digitální laboratoři.
                  </p>
              </div>
              <div class="relative max-w-7xl ml-0 mr-auto lg:mx-auto pt-0 pb-6 lg:py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
                <form data-netlify="true" method="post" netlify-honeypot="bot-field" name="praxe" class="mt-8 sm:flex">
                    <p class="hidden">
                    <label>Don’t fill this out if you’re human: <input name="bot-field" /></label>
                    </p>
                    <input type="hidden" name="form-name" value="praxe" />
                  <label for="email-address" class="sr-only">Email address</label>
                  <input id="email-address" name="email-address" type="email" autocomplete="email" required="" class="w-full px-5 py-3 placeholder-gray-500 focus:ring-red-500 focus:border-red-500 sm:max-w-xs border-gray-300" placeholder="Zadejte Váš email..." />
                  <div class="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                    <button type="submit" class="uppercase w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                      mám zájem
                    </button>
                  </div>
                </form>
              </div>
            </div>
              <!-- <div class="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                <h1 class="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                  <span class="block text-white">Take control of your</span>
                  <span class="block text-indigo-200">customer support</span>
                </h1>
                <p class="mt-6 max-w-lg mx-auto text-center text-xl text-indigo-200 sm:max-w-3xl">
                  Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet fugiat veniam occaecat fugiat aliqua.
                </p>
                <div class="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                  <div class="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
                    <a href="#" class="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-indigo-700 bg-white hover:bg-indigo-50 sm:px-8">
                      Get started
                    </a>
                    <a href="#" class="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-500 bg-opacity-60 hover:bg-opacity-70 sm:px-8">
                      Live demo
                    </a>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>


</template>
