<template>
  <!-- HEADER -->
  <div class="bg-gray-100">
    <div class="max-w-7xl mx-auto py-8 px-4 sm:py-12 sm:px-6 lg:px-8">
      <div class="text-left">
        <!-- <h2 class="text-base font-semibold text-indigo-600 tracking-wide uppercase">Pricing</h2> -->
        <h2
          class="
            text-2xl
            font-extrabold
            tracking-tight
            text-red-600
            sm:text-4xl
          "
        >
          Moderní a precizní výroba
        </h2>
        <p class="max-w-xl mt-2 mr-auto text-lg sm:text-xl text-gray-500">
          Seznamte se s nejzajímavější zubní laboratoři
        </p>
      </div>
    </div>
  </div>
  <!-- HEADER END -->

  <!-- SECTION END -->

  <div class="bg-white">
    <div
      class="
        max-w-2xl
        mx-auto
        px-4
        sm:px-6
        py-8
        sm:py-14 sm:pb-24
        lg:max-w-7xl lg:px-8
      "
    >
      <!-- <div class="max-w-3xl mx-auto text-center">
    <h2 class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">Protect your device</h2>
    <p class="mt-4 text-gray-500">As a digital creative, your laptop or tablet is at the center of your work. Keep your device safe with a fabric sleeve that matches in quality and looks.</p>
    </div> -->

      <div class="mt-0 space-y-16">
        <div
          v-for="(feature, featureIdx) in features"
          :key="feature.name"
          class="
            flex flex-col-reverse
            lg:grid lg:grid-cols-12 lg:gap-x-8 lg:items-center
          "
        >
          <div
            :class="[
              featureIdx % 2 === 0
                ? 'lg:col-start-2'
                : 'lg:col-start-7 xl:col-start-7',
              'mt-6 lg:mt-0 lg:row-start-1 lg:col-span-4 xl:col-span-4',
            ]"
          >
            <h3 class="text-2xl font-medium text-red-600">
              {{ feature.name }}
            </h3>
            <p class="mt-2 text-lg text-gray-500">{{ feature.description }}</p>
          </div>
          <div
            :class="[
              featureIdx % 2 === 0
                ? 'lg:col-start-7 xl:col-start-6'
                : 'lg:col-start-2',
              'flex-auto lg:row-start-1 lg:col-span-4 xl:col-span-6',
            ]"
          >
            <div
              class="
                aspect-w-5 aspect-h-3
                rounded-lg
                bg-gray-100
                overflow-hidden
              "
            >
              <img
                :src="feature.imageSrc"
                :alt="feature.imageAlt"
                class="object-center object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- SECTION NEW END  -->
  <LogoCloud />
  <SignupSection />
  <GalleryOverview />
</template>

<script>
import SignupSection from '@/components/SignupSection.vue'
import LogoCloud from '@/components/LogoCloud.vue'
import GalleryOverview from '@/components/GalleryOverview.vue'

const features = [
  {
    name: '1. Kontrola podkladů',
    description:
      'Dobrý otisk či sken je základem pro následně kvalitně zhotovenou zubní náhradu. Proto prvotní kontrole věnujeme maximální pozornost.',
    imageSrc: require('@/assets/img/components/production_01.jpg'),
    imageAlt: '',
  },
  {
    name: '2. CAD modelace',
    description:
      'Veškeré práce či jejich části u nás zhotovujeme digitálně. Šetří nám to čas a zvyšuje přesnost provedení.',
    imageSrc: require('@/assets/img/components/production_02.jpg'),
    imageAlt: '',
  },
  {
    name: '3. CAM výroba konstrukce',
    description:
      'Virtuálně vymodelované náhrady poté frézujeme, sintrujeme či tiskneme na nejmodernějších technologiích. Používáme při tom pouze certifikované materiály. I proto si můžeme dovolit pro vás delší záruky.',
    imageSrc: require('@/assets/img/components/production_03.jpg'),
    imageAlt: '',
  },
  {
    name: '4. 3D tisk modelu',
    description:
      'U většiny prací také tiskneme plastové modely k dosazení a dohotovení. ',
    imageSrc: require('@/assets/img/components/production_04.jpg'),
    imageAlt: '',
  },
  {
    name: '5. Dohotovení práce',
    description:
      'Fixní náhrady následně ještě vrstvíme keramikou anebo pouze glazujeme do lesku jak tomu je například u anatomických zirkonových prací. Lidský faktor je navzdory mnoha kvalitním přístrojů stále naprosto stěžejní.',
    imageSrc: require('@/assets/img/components/production_05.jpg'),
    imageAlt: '',
  },
  {
    name: '6. Kontrola a odeslání',
    description:
      'Nepošleme k vám žádnou hotovou práci, se kterou bychom nebyli spokojeni. Finální kontrola je prováděna hned několika zubními techniky.',
    imageSrc: require('@/assets/img/components/production_06.jpg'),
    imageAlt: '',
  },
]

export default {
  components: {
    SignupSection,
    LogoCloud,
    GalleryOverview,
  },
  setup() {
    return {
      features,
    }
  },
}
</script>
