<template>
  <div class="bg-white">
    <div class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
      <h2 class="text-3xl font-extrabold text-red-600 sm:text-4xl">
        Kontaktní údaje
      </h2>
      <div
        class="max-w-lg mx-auto md:max-w-none md:grid md:grid-cols-2 md:gap-8"
      >
        <div>
          <div class="mt-3">
            <ul class="text-lg text-gray-500">
              <li>Mojmírovo nám. 25/25</li>
              <li>612 00 Brno</li>
              <li>Provozní doba: 8.00 - 17.00</li>
              <div class="text-sm mt-2">
                <li>IČ: 46969144</li>
                <li>DIČ: CZ46969144</li>
                <li>Datová schránka: hutspax</li>
                <li>
                  Společnost zapsána na KS v Brně pod spisovou značkou C 7075
                </li>
              </div>
            </ul>
          </div>
          <!-- <div class="mt-9">
            <div class="flex">
              <div class="flex-shrink-0">
                <PhoneIcon class="h-6 w-6 text-gray-400" aria-hidden="true" />
              </div>
              <div class="ml-3 text-base text-gray-500">
                <p>
                  +1 (555) 123 4567
                </p>
                <p class="mt-1">
                  Mon-Fri 8am to 6pm PST
                </p>
              </div>
            </div>
            <div class="mt-6 flex">
              <div class="flex-shrink-0">
                <MailIcon class="h-6 w-6 text-gray-400" aria-hidden="true" />
              </div>
              <div class="ml-3 text-base text-gray-500">
                <p>
                  support@example.com
                </p>
              </div>
            </div>
          </div> -->
        </div>
        <div class="mt-12 sm:mt-16 md:mt-0">
          <!-- <div class="mt-3">
            <p class="text-lg text-gray-500">
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Magni, repellat error corporis doloribus similique, voluptatibus numquam quam, quae officiis facilis.
            </p>
          </div> -->
          <div class="mt-3">
            <div class="flex">
              <div class="flex-shrink-0">
                <PhoneIcon class="h-6 w-6 text-gray-400" aria-hidden="true" />
              </div>
              <div class="ml-3 text-base text-gray-500">
                <a
                  href="tel:+420 725 433 894"
                  class="underline hover:text-red-600"
                >
                  +420 725 433 894
                </a>
                <p class="mt-1">Po - Pá 8:00 - 16:30</p>
              </div>
            </div>
            <div class="mt-6 flex">
              <div class="flex-shrink-0">
                <MailIcon class="h-6 w-6 text-gray-400" aria-hidden="true" />
              </div>
              <div class="ml-3 text-base text-gray-500">
                <a
                  href="mailto:info@eurozahn.cz"
                  class="underline hover:text-red-600"
                >
                  info@eurozahn.cz
                </a>
              </div>
            </div>
            <ul class="mt-6 text-lg font-bold text-gray-500">
              <li>Dodáváme po celé ČR</li>
              <li>Prácu Vám dovezieme aj na Slovensko</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Pridat mapu -->
  <!-- GOOGLE MAP IFRAME -->
  <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
    <iframe
      width="100%"
      height="300"
      frameborder="0"
      style="border: 0"
      src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBuqWREhFvZUax-TAbxVLw3EDLPE6uvurs&q=Eurozahn+Brno,Czechia"
    >
      <!-- src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2552.056799614893!2d16.593437400306442!3d49.22557875125445!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471294091431279f%3A0xc50e0cd191900154!2sEurozahn!5e0!3m2!1sen!2scz!4v1629703842521!5m2!1sen!2scz"> -->
    </iframe>
  </div>
  <!-- GOOGLE MAP IFRAME END -->
</template>

<script>
import { MailIcon, PhoneIcon } from '@heroicons/vue/outline'

export default {
  components: {
    MailIcon,
    PhoneIcon,
  },
}
</script>
