<template>
  <div class="bg-gray-100">
    <div class="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
      <div class="text-center">
        <!-- <h2 class="text-base font-semibold text-indigo-600 tracking-wide uppercase">Pricing</h2> -->
        <p class="mt-1 text-4xl font-extrabold text-red-600 sm:text-5xl sm:tracking-tight lg:text-6xl">Moderní a precizní výroba</p>
        <p class="max-w-xl mt-5 mx-auto text-xl text-gray-500">Seznamte se s nejzajímavější zubní laboratoři</p>
      </div>
    </div>
  </div>
</template>
