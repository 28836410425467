<template>
<!-- HEADER -->
  <div class="bg-gray-100">
    <div class="max-w-7xl mx-auto py-8 px-4 sm:py-12 sm:px-6 lg:px-8">
      <div class="text-left">
        <!-- <h2 class="text-base font-semibold text-indigo-600 tracking-wide uppercase">Pricing</h2> -->
        <h2 class="text-2xl font-extrabold tracking-tight text-red-600 sm:text-4xl">Přidejte se k nám</h2>
        <p class="max-w-xl mt-2 mr-auto text-lg sm:text-xl text-gray-500">U nás zažijete jiné prostředí zubní laboratoře než jste zvyklí</p>
      </div>
    </div>
  </div>
<!-- HEADER END -->

<!-- WRAPPER -->
<div class="max-w-7xl pt-14 mx-auto sm:px-6 lg:px-8">
<!-- SUBHEADER -->

  <div class="pb-5 px-4 sm:px-0 border-b border-gray-200">
    <span class="inline-flex items-center px-2 py-0.5 mb-2 rounded text-xs font-medium bg-red-100 text-red-600">
    2 nabídky
    </span>
    <h3 class="text-lg leading-6 font-medium text-gray-900">
      Aktuálně volné pozice
    </h3>
    <!-- <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-600">
    2 nabídky
    </span> -->
    <!-- <p class="mt-2 max-w-4xl text-sm text-gray-500">Workcation is a property rental website. Etiam ullamcorper massa viverra consequat, consectetur id nulla tempus. Fringilla egestas justo massa purus sagittis malesuada.</p> -->
  </div>

<!-- SUBHEADER END -->

<!-- CONTENT -->
<div class="py-8 grid grid-flow-col grid-rows-2 gap-8 lg:grid-flow-row lg:grid-cols-2 lg:grid-rows-none">
    <!-- ITEM -->
    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
        <div class="px-4 py-5 sm:px-6">
        <h3 class="uppercase text-lg leading-6 font-medium text-red-600">
            ZUBNÍ TECHNIK - FIXNÍ PROTETIKA
        </h3>
        <p class="uppercase mt-1 max-w-2xl text-sm text-gray-500">
            Plný úvazek
        </p>
        </div>
        <div class="border-t border-gray-200">
        <dl>
            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
                Popis:
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                Do naší digitální zubní laboratoře v Brně přijmeme dalšího zubního
technika s orientací na fixní protetiku.
            </dd>
            </div>
            <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
                Požadujeme:
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <ul class="list-disc list-inside">
                    <li>alespoň 5 let praxe ve fixní protetice</li>
                    <li>zkušenost s CAD/CAM velkou výhodou, ale není podmínkou</li>
                    <li>schopnost práce v týmu a respektování výrobních a interních procesů</li>
                    <li>ochota učit se novým dovednostem</li>
                    <li>samostatnost a zodpovědnost</li>
                    <li>zbytek naučíme:-)</li>
                </ul>
            </dd>
            </div>
            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
                Nabízíme:
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <ul class="list-disc list-inside">
                    <li>nadstandardní platové ohodnocení</li>
                    <li>8hodinovou pracovní dobu (lze variabilně)</li>
                    <li>dostatek práce</li>
                    <li>moderní, digitální pracoviště s komfortním systémem práce v laboratoři</li>
                    <li>firemní mobilní telefon + tarif</li>
                    <li>odborná školení</li>
                    <li>dovolená dle ZP</li>
                    <li>3 sick days</li>
                </ul>
            </dd>
            </div>
            <!-- <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
                Email address
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                margotfoster@example.com
            </dd>
            </div> -->
            <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
                Platové ohodnocení:
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                40 - 60.000,-Kč / měs.
            </dd>
            </div>
        </dl>
        </div>
    </div>
    <!-- ITEM END -->

    <!-- ITEM -->
    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
        <div class="px-4 py-5 sm:px-6">
        <h3 class="uppercase text-lg leading-6 font-medium text-red-600">
            Zubní technik na výpomoc - fixní protetika
        </h3>
        <p class="uppercase mt-1 max-w-2xl text-sm text-gray-500">
            Zkrácený úvazek
        </p>
        </div>
        <div class="border-t border-gray-200">
        <dl>
            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
                Popis:
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                Do naší digitální zubní laboratoře v Brně přijmeme na výpomoc ZT se zkušeností.
Možno pracovat 2-3 dny v týdnu nebo dle domluvy. Vhodné pro techničky na MD, zkrácený úvazek anebo pro ZT, kteří nejsou tolik vytížení.
            </dd>
            </div>
            <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
                Požadujeme:
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <ul class="list-disc list-inside">
                    <li>alespoň 5 let praxe ve fixní protetice</li>
                    <li>schopnost práce v týmu a respektování výrobních a interních procesů</li>
                    <li>samostatnost, zodpovědnost, spolehlivost</li>
                    <li>zkušenosti s CAD/CAM výhodou</li>
                </ul>
            </dd>
            </div>
            <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
                Nabízíme:
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <ul class="list-disc list-inside">
                    <li>pouze napalování keramiky či glazování zirkonu</li>
                    <li>nadstandardní platové ohodnocení za odvedenou práci (žádné normy)</li>
                    <li>variabilní pracovní doba</li>
                    <li>dostatek práce</li>
                    <li>moderní, digitální pracoviště s komfortním systémem práce v laboratoři</li>
                </ul>
            </dd>
            </div>
            <!-- <div class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
                Email address
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                margotfoster@example.com
            </dd>
            </div> -->
            <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">
                Platové ohodnocení:
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                Nadstandardní - dle dohody
            </dd>
            </div>
        </dl>
        </div>
    </div>
    <!-- ITEM END -->
</div>
<!-- CONTENT END -->

<!-- FOOTER -->

  <div class="pb-14 px-4 sm:px-0 border-b border-gray-200">
    <h3 class="text-lg leading-6 font-medium text-red-600">
      Zaujala Vás některá z nabídek?
    </h3>
    <p class="mt-2 max-w-4xl text-sm text-gray-500">Neváhejte a pošlete nám na <a class="underline text-red-600 hover:text-red-400" href="mailto:info@eurozahn.cz">info@eurozahn.cz</a> své CV, rádi Vás poznáme.</p>
  </div>

<!-- FOOTER END -->

</div>
<!-- WRAPPER END -->
    <SignupInternship/>
    <LogoCloud/>
    <GalleryOverview/>
</template>

<script>
// import { PaperClipIcon } from '@heroicons/vue/solid'
import SignupInternship from '@/components/SignupInternship.vue'
import LogoCloud from '@/components/LogoCloud.vue'
import GalleryOverview from '@/components/GalleryOverview.vue'

export default {
  components: {
      SignupInternship,
    LogoCloud,
    GalleryOverview,
  },
}
</script>