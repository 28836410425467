<template>
  <div class="bg-white">
    <div class="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
      <div class="space-y-12">
        <div class="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
          <h2 class="text-3xl font-extrabold tracking-tight sm:text-4xl text-red-600">Co umíme</h2>
          <p class="text-xl text-gray-500">Specializujeme se na fixní protetiku. Práce na implantátech jsou pro nás rutina. Hravě si poradíme i se složitějšími řešeními jako jsou Brånemark či hybridní můstky. Podívejte se do galerie na některé naše práce.</p>
        </div>
      <!-- SMALL GALLERY -->
      <ul role="list" class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
          <li v-for="(person, index) in people" :key="person.name" class="relative">
            <!-- <span>{{ index }}</span> -->
            <div class="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-red-500 overflow-hidden">
              <img :src="person.imageUrl[0]" alt="" class="object-cover pointer-events-none group-hover:opacity-75" />
              <button @click="showMultiple(index)" type="button" class="absolute inset-0 focus:outline-none">
                <span class="sr-only">Zobrazit detail {{ person.name }}</span>
              </button>
            </div>
            <p class="mt-2 block text-sm font-medium text-red-600 truncate pointer-events-none">{{ person.name }}</p>
            <p class="block text-sm font-medium text-gray-500 pointer-events-none">{{ person.role }}</p>
          </li>
        </ul>
        <!-- SMALL GALLERY END -->

        <!-- SMALL GALLERY -->
      <!-- <ul role="list" class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
          <li v-for="item in gallery2" :key="item.name" class="relative">
            <div class="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-red-500 overflow-hidden">
              <img :src="item.imageUrl" alt="" class="object-cover pointer-events-none group-hover:opacity-75" />
              <button type="button" class="absolute inset-0 focus:outline-none">
                <span class="sr-only">View details for {{ item.name }}</span>
              </button>
            </div>
            <p class="mt-2 block text-sm font-medium text-red-600 truncate pointer-events-none">{{ item.name }}</p>
            <p class="block text-sm font-medium text-gray-500 pointer-events-none">{{ item.role }}</p>
          </li>
        </ul> -->
        <!-- SMALL GALLERY END -->


        <!-- RANDOM GALLERY -->
      <ul role="list" class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
        <li v-for="(image, index) in randomImages" :key="image.name" class="relative">
          <!-- <span>{{ index }}</span> -->
            <div class="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-red-500 overflow-hidden">
              <img :src="image.imageUrl" alt="" class="object-cover pointer-events-none group-hover:opacity-75" />
              <button @click="showRandom(index)" type="button" class="absolute inset-0 focus:outline-none">
                <span class="sr-only">Zobrazit detail {{ image.name }}</span>
              </button>
            </div>
            <!-- <span>{{ image[index] }}</span> -->
            <!-- <span>{{ randomImagesUrl }}</span> -->
            <p class="mt-2 block text-sm font-medium text-red-600 truncate pointer-events-none">{{ image.name }}</p>
            <p class="block text-sm font-medium text-gray-500 pointer-events-none">{{ image.role }}</p>
        </li>
          <!-- <li class="relative">
            <div class="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-red-500 overflow-hidden">
              <img @click="showSingle" :src="image1.imageUrl" alt="" class="object-cover pointer-events-none group-hover:opacity-75" />
              <button type="button" class="absolute inset-0 focus:outline-none">
                <span class="sr-only">View details for {{ image1.name }}</span>
              </button>
            </div>
            <p class="mt-2 block text-sm font-medium text-red-600 truncate pointer-events-none">{{ image1.name }}</p>
            <p class="block text-sm font-medium text-gray-500 pointer-events-none">{{ image1.role }}</p>
          </li>
                    <li class="relative">
            <div class="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-red-500 overflow-hidden">
              <img :src="image2.imageUrl" alt="" class="object-cover pointer-events-none group-hover:opacity-75" />
              <button type="button" class="absolute inset-0 focus:outline-none">
                <span class="sr-only">View details for {{ image2.name }}</span>
              </button>
            </div>
            <p class="mt-2 block text-sm font-medium text-red-600 truncate pointer-events-none">{{ image2.name }}</p>
            <p class="block text-sm font-medium text-gray-500 pointer-events-none">{{ image2.role }}</p>
          </li>
                    <li class="relative">
            <div class="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-red-500 overflow-hidden">
              <img :src="image3.imageUrl" alt="" class="object-cover pointer-events-none group-hover:opacity-75" />
              <button type="button" class="absolute inset-0 focus:outline-none">
                <span class="sr-only">View details for {{ image3.name }}</span>
              </button>
            </div>
            <p class="mt-2 block text-sm font-medium text-red-600 truncate pointer-events-none">{{ image3.name }}</p>
            <p class="block text-sm font-medium text-gray-500 pointer-events-none">{{ image3.role }}</p>
          </li>
                    <li class="relative">
            <div class="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-red-500 overflow-hidden">
              <img :src="image4.imageUrl" alt="" class="object-cover pointer-events-none group-hover:opacity-75" />
              <button type="button" class="absolute inset-0 focus:outline-none">
                <span class="sr-only">View details for {{ image4.name }}</span>
              </button>
            </div>
            <p class="mt-2 block text-sm font-medium text-red-600 truncate pointer-events-none">{{ image4.name }}</p>
            <p class="block text-sm font-medium text-gray-500 pointer-events-none">{{ image4.role }}</p>
          </li> -->
        </ul>
        <!-- RANDOM GALLERY END -->

        <!-- LARGE GALLERY -->
        <!-- <ul class="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8">
          <li v-for="person in people" :key="person.name">
            <div class="space-y-4">
              <div class="aspect-w-3 aspect-h-2">
                <img class="object-cover shadow-lg rounded-lg pointer-events-none group-hover:opacity-75" :src="person.imageUrl" alt="" />
              </div>

              <div class="space-y-2">
                <div class="text-lg leading-6 font-medium space-y-1">
                  <h3>{{ person.name }}</h3>
                  <p class="text-red-600">{{ person.role }}</p>
                </div>
              </div>
            </div>
          </li>
        </ul> -->
        <!-- LARGE GALLERY END -->

        <!-- VUE LIGHTBOX TEST -->

        <div>
          <!-- all props & events -->
          <vue-easy-lightbox
            scrollDisabled
            :visible="visible"
            :imgs="imgs"
            :index="index"
            @hide="handleHide"
          ></vue-easy-lightbox>
        </div>

        <!-- VUE LIGHTBOX TEST END -->
      </div>
    </div>
  </div>
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox'

const people = [
  {
    name: 'e.max',
    role: 'sólo korunky ve frontálním úseku',
    imageUrl: [
      require('@/assets/img/gallery/bevedent/01_front.jpg'),
      require('@/assets/img/gallery/bevedent/02.jpg'),
      require('@/assets/img/gallery/bevedent/03.jpg'),
      require('@/assets/img/gallery/bevedent/04.jpg'),
      require('@/assets/img/gallery/bevedent/05.jpg'),
      require('@/assets/img/gallery/bevedent/06.jpg'),
      require('@/assets/img/gallery/bevedent/07.jpg'),
      ],
    twitterUrl: '#',
    linkedinUrl: '#',
  },{
    name: 'zirkon anatomický',
    role: 'sólo korunky 5,6,7',
    imageUrl: [
      require('@/assets/img/gallery/nagreba/01_front.jpg'),
      require('@/assets/img/gallery/nagreba/02.jpg'),
      require('@/assets/img/gallery/nagreba/03.jpg'),
      require('@/assets/img/gallery/nagreba/04.jpg'),
      ],
    twitterUrl: '#',
    linkedinUrl: '#',
  },{
    name: 'metalokeramika',
    role: 'zubní náhrada ve frontálním úseku',
    imageUrl: [
      require('@/assets/img/gallery/tihon/01_front.jpg'),
      require('@/assets/img/gallery/tihon/02.jpg'),
      require('@/assets/img/gallery/tihon/03.jpg'),
      require('@/assets/img/gallery/tihon/04.jpg'),
      require('@/assets/img/gallery/tihon/05.jpg'),
      require('@/assets/img/gallery/tihon/06.jpg'),
      ],
    twitterUrl: '#',
    linkedinUrl: '#',
  },{
    name: 'zirkon vrstvený',
    role: 'zub 21 na zirkonovém abutmentu',
    imageUrl: [
      require('@/assets/img/gallery/zima/01_front.jpeg'),
      require('@/assets/img/gallery/zima/02.jpg'),
      require('@/assets/img/gallery/zima/03.jpg'),
      require('@/assets/img/gallery/zima/04.jpg'),
      require('@/assets/img/gallery/zima/05.jpg'),
      require('@/assets/img/gallery/zima/06.jpg'),
      ],
    twitterUrl: '#',
    linkedinUrl: '#',
  },{
    name: 'zirkon anatomický',
    role: 'kompletní rekonstrukce HČ',
    imageUrl: [
      require('@/assets/img/gallery/other/01_front.jpg'),
      require('@/assets/img/gallery/other/02.jpg'),
      require('@/assets/img/gallery/other/03.jpg'),
      require('@/assets/img/gallery/other/04.jpg'),
      require('@/assets/img/gallery/other/05.jpg'),
      require('@/assets/img/gallery/other/06.jpg'),
      ],
    twitterUrl: '#',
    linkedinUrl: '#',
  },{
    name: 'zirkon vrstvený',
    role: 'rekonstrukce frontálního úseku',
    imageUrl: [
      require('@/assets/img/gallery/other-1/01_front.jpg'),
      require('@/assets/img/gallery/other-1/02.jpg'),
      require('@/assets/img/gallery/other-1/03.jpg'),
      require('@/assets/img/gallery/other-1/04.jpg'),
      ],
    twitterUrl: '#',
    linkedinUrl: '#',
  }
]

const gallery2 = [{
    name: 'Výroba sintrovaných CoCr prací',
    role: 'CoCr pod metalokeramiku',
    imageUrl:
      require('@/assets/img/gallery/random/01.jpg'),
    twitterUrl: '#',
    linkedinUrl: '#',
  },{
    name: 'Brånemark',
    role: 's kovovou výstuží',
    imageUrl:
      require('@/assets/img/gallery/random/02.jpg'),
    twitterUrl: '#',
    linkedinUrl: '#',
  },{
    name: 'Brånemark',
    role: 's kovovou výstuží',
    imageUrl:
      require('@/assets/img/gallery/random/03.jpg'),
    twitterUrl: '#',
    linkedinUrl: '#',
  },{
    name: 'Anatomický zirkon',
    role: '3D tištěný model + můstky na ROOTT implantátech',
    imageUrl:
      require('@/assets/img/gallery/random/04.jpg'),
    twitterUrl: '#',
    linkedinUrl: '#',
  },{
    name: 'Anatomický zirkon',
    role: 'Můstky pro fixaci',
    imageUrl:
      require('@/assets/img/gallery/random/05.jpg'),
    twitterUrl: '#',
    linkedinUrl: '#',
  },{
    name: 'Anatomický zirkon',
    role: 'Popis práce',
    imageUrl:
      require('@/assets/img/gallery/random/06.jpg'),
    twitterUrl: '#',
    linkedinUrl: '#',
  },{
    name: 'Anatomický zirkon',
    role: 'Popis práce',
    imageUrl:
      require('@/assets/img/gallery/random/07.jpg'),
    twitterUrl: '#',
    linkedinUrl: '#',
  },{
    name: 'Brånemark',
    role: 'nesený na LASAK implantátech',
    imageUrl:
      require('@/assets/img/gallery/random/08.jpg'),
    twitterUrl: '#',
    linkedinUrl: '#',
  },{
    name: 'Brånemark',
    role: 'nesený na LASAK implantátech',
    imageUrl:
      require('@/assets/img/gallery/random/09.jpg'),
    twitterUrl: '#',
    linkedinUrl: '#',
  },{
    name: 'Brånemark',
    role: 'nesený na LASAK implantátech',
    imageUrl:
      require('@/assets/img/gallery/random/10.jpg'),
    twitterUrl: '#',
    linkedinUrl: '#',
  },{
    name: 'Brånemark',
    role: 'nesený na LASAK implantátech',
    imageUrl:
      require('@/assets/img/gallery/random/11.jpg'),
    twitterUrl: '#',
    linkedinUrl: '#',
  },{
    name: 'Brånemark',
    role: 'kompletní HČ, sólo korunky',
    imageUrl:
      require('@/assets/img/gallery/random/12.jpg'),
    twitterUrl: '#',
    linkedinUrl: '#',
  },{
    name: 'Metalokeramika',
    role: 'MK můstky',
    imageUrl:
      require('@/assets/img/gallery/random/13.jpg'),
    twitterUrl: '#',
    linkedinUrl: '#',
  },{
    name: 'Zirkon vrstvený',
    role: 'zub 11',
    imageUrl:
      require('@/assets/img/gallery/random/14.jpg'),
    twitterUrl: '#',
    linkedinUrl: '#',
  },{
    name: 'Brånemark',
    role: '',
    imageUrl:
      require('@/assets/img/gallery/random/15.jpg'),
    twitterUrl: '#',
    linkedinUrl: '#',
  },{
    name: 'Zirkon vrstvený',
    role: '',
    imageUrl:
      require('@/assets/img/gallery/random/16.jpg'),
    twitterUrl: '#',
    linkedinUrl: '#',
  },{
    name: 'Zirkon anatomický',
    role: 'sólo zirkonové korunky na 3D tištěném modelu',
    imageUrl:
      require('@/assets/img/gallery/random/17.jpg'),
    twitterUrl: '#',
    linkedinUrl: '#',
  }]

var random1 = Math.floor(Math.random() * (3 - 0) + 0);
var random2 = Math.floor(Math.random() * (6 - 4) + 4);
var random3 = Math.floor(Math.random() * (8 - 7) + 7);
var random4 = Math.floor(Math.random() * (17 - 9) + 7);

var image1 = gallery2[random1];
var image2 = gallery2[random2];
var image3 = gallery2[random3];
var image4 = gallery2[random4];

var randomImages = [
  image1,
  image2,
  image3,
  image4
]

var randomImagesUrl = [
  image1.imageUrl,
  image2.imageUrl,
  image3.imageUrl,
  image4.imageUrl
]

export default {
  components: {
    VueEasyLightbox,
  },
  setup() {
    return {
      gallery2,
      people,
      image1,
      image2,
      image3,
      image4,
      randomImages,
      randomImagesUrl
    }
  },
  data() {
    return {
      imgs: '', // Img Url , string or Array of string
      visible: false,
      index: 0 // default: 0
    }
  },
  methods: {
    showSingle(index) {
      // this.imgs = 'http://via.placeholder.com/350x150'
      // or
      // this.imgs = {
      //   title: 'this is a placeholder',
      //   src: 'http://via.placeholder.com/350x150'
      // }
      this.imgs = randomImages[index].imageUrl
      this.show()
    },
    showRandom(index) {
      // this.imgs = [
      //   require('@/assets/img/gallery/nagreba/01_front.jpg'),
      //   require('@/assets/img/gallery/nagreba/02.jpg')
      // ]
      // or
      this.imgs = randomImagesUrl
      // this.imgs = [
      //   { title: 'test img', src: require('@/assets/img/gallery/nagreba/01_front.jpg') },
      //   require('@/assets/img/gallery/nagreba/02.jpg')
      // ]
      // allow mixing

      this.index = index // index of imgList
      this.show()
    },
    showMultiple(index) {
      // this.imgs = [
      //   require('@/assets/img/gallery/nagreba/01_front.jpg'),
      //   require('@/assets/img/gallery/nagreba/02.jpg')
      // ]
      // or
      this.imgs = people[index].imageUrl
      // this.imgs = [
      //   { title: 'test img', src: require('@/assets/img/gallery/nagreba/01_front.jpg') },
      //   require('@/assets/img/gallery/nagreba/02.jpg')
      // ]
      // allow mixing

      this.index = 0 // index of imgList
      this.show()
    },
    show() {
      this.visible = true
    },
    handleHide() {
      this.visible = false
    }
  }
}
</script>