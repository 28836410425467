<template>
  <div class="bg-gray-100">
    <div class="max-w-7xl text-center mx-auto py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
      <h2 class="text-4xl font-bold text-red-600">
        Nástroje bez kompromisů
      </h2>
      <div class="flow-root mt-8 lg:mt-10">
        <div class="-mt-4 -ml-8 flex flex-wrap justify-between lg:-ml-4 items-baseline">
          <div class="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
            <img class="h-4" src="@/assets/graphics/logo-cloud/asiga.png" alt="asiga" />
          </div>
          <div class="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
            <img class="h-6" src="@/assets/graphics/logo-cloud/eos.png" alt="EOS" />
          </div>
          <div class="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
            <img class="h-6" src="@/assets/graphics/logo-cloud/medit.png" alt="vivadent" />
          </div>
          <div class="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
            <img class="h-6" src="@/assets/graphics/logo-cloud/exocad.png" alt="exocad" />
          </div>
          <div class="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
            <img class="h-6" src="@/assets/graphics/logo-cloud/3shape.png" alt="3shape" />
          </div>
          <div class="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
            <img class="h-10" src="@/assets/graphics/logo-cloud/vivadent.png" alt="vivadent" />
          </div>
        </div>
      </div>
      <p class="mt-8 text-base text-gray-600">Používáme výhradně kvalitné a ověřené značky výrobků ze zápádních zemí.</p>
    </div>
  </div>
</template>
