<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="bg-white">
    <div class="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:pb-4 sm:px-6 lg:px-8">
      <div
        class="
          grid grid-flow-col
          lg:grid-flow-row
          grid-cols-1
          lg:grid-cols-3
          grid-rows-2
          lg:grid-rows-1
        "
      >
        <!-- LEFT PANEL -->
        <div
          class="col-span-1 lg:col-span-2 row-span-1 bg-white overflow-hidden"
        >
          <div class="">
            <!-- Content goes here -->
            <div class="lg:col-span-1">
              <!-- <h2 class="text-lg leading-6 font-semibold text-gray-500 uppercase tracking-wider">
                            PRODUKTY
                            </h2> -->
              <p
                class="
                  text-3xl
                  font-extrabold
                  text-red-600
                  sm:text-4xl
                  lg:text-5xl
                "
              >
                Dodací lhůty
              </p>
              <p class="mb-4 text-xl text-gray-500">
                Od předání otisků/skenu, až po doručení hotové práce do Vaší
                ordinace.
              </p>
            </div>
            <ul role="list" class="">
              <li
                v-for="product in products"
                :key="product.id"
                class="px-0 py-4 sm:px-0"
              >
                <!-- Your content -->
                <p class="text-4xl font-bold text-red-600">
                  {{ product.leadTime }}
                  <span class="text-sm font-normal text-gray-400">
                    {{ product.leadTimeDescription }}
                  </span>
                </p>
                <div
                  class="mt-1 h-3 border-2 rounded-md border-red-600"
                  :class="[product.barSize]"
                ></div>
                <ul class="mt-3">
                  <li
                    v-for="type in product.productTypes"
                    :key="type.id"
                    class="
                      pl-2
                      mt-2
                      uppercase
                      text-sm
                      font-semibold
                      border-l-2
                      text-gray-500
                      border-red-600
                    "
                  >
                    {{ type }}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <!-- LEFT PANEL END -->

        <!-- RIGHT PANEL -->
        <div
          class="
            col-span-1
            row-span-1
            bg-white
            overflow-hidden
            divide-y divide-gray-200
          "
        >
          <!-- <div class="px-4 py-5 sm:px-6"> -->
          <!-- Content goes here -->
          <!-- We use less vertical padding on card headers on desktop than on body sections -->
          <!-- </div> -->
          <div class="px-4 py-5 sm:p-6">
            <!-- Content goes here -->
            <div class="text-center">
              <h2 class="font-semibold text-2xl text-red-600">
                Garantuji dodání v termínu
              </h2>
              <div v-for="person in people" :key="person.name">
                <img
                  class="w-7/12 md:w-4/12 lg:w-6/12 mx-auto my-4"
                  :src="person.imageUrl"
                  alt=""
                />
                <div class="text-md">
                  <p>
                    <span class="uppercase font-medium">{{ person.name }}</span
                    >, {{ person.title }}
                  </p>
                  <p class="-mt-1 font-normal lowercase text-gray-500">
                    {{ person.role }}
                  </p>
                </div>
              </div>
              <div class="mt-8">
                <h3 class="font-semibold text-lg text-red-600">
                  Dodáváme do celé ČR i SK.
                </h3>
                <p class="-mt-1 text-gray-500">Jednotné dodací lhůty i ceny.</p>
                <div class="flex flex-row mx-auto mt-2 justify-center">
                  <img
                    class="px-2"
                    src="@/assets/graphics/flag_cz.png"
                    alt=""
                  />
                  <img
                    class="px-2"
                    src="@/assets/graphics/flag_sk.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- RIGHT PANEL END -->
      </div>
    </div>
  </div>
</template>

<script>
const products = [
  {
    id: 1,
    leadTime: 7,
    barSize: 'w-7/12',
    leadTimeDescription: 'pracovních dní',
    productTypes: ['VRSTVENÁ PRÁCE', 'PRÁCE NA IMPLANTÁTECH'],
  },
  {
    id: 2,
    leadTime: 3,
    barSize: 'w-3/12',
    leadTimeDescription: 'pracovních dní',
    productTypes: ['KONSTRUKCE NA ZKOUŠKU', 'ANATOMICKÁ PRÁCE'],
  },
  {
    id: 3,
    leadTime: 2,
    barSize: 'w-2/12',
    leadTimeDescription: 'pracovní dny',
    productTypes: ['PROVIZORNÍ NÁHRADA'],
  },
]

const people = [
  {
    name: 'Ondřej Kudera',
    title: 'MSc',
    role: 'výkonný ředitel',
    imageUrl: require('@/assets/img/team/ok_2.jpg'),
    twitterUrl: '#',
    linkedinUrl: '#',
  },
]

export default {
  setup() {
    return {
      products,
      people,
    }
  },
}
</script>
