<template>
<!-- HEADER -->
  <div class="bg-gray-100">
    <div class="max-w-7xl mx-auto py-8 px-4 sm:py-12 sm:px-6 lg:px-8">
      <div class="text-left">
        <!-- <h2 class="text-base font-semibold text-indigo-600 tracking-wide uppercase">Pricing</h2> -->
        <h2 class="text-2xl font-extrabold tracking-tight text-red-600 sm:text-4xl">Indikace </h2>
        <p class="max-w-xl mt-2 mr-auto text-lg sm:text-xl text-gray-500"></p>
      </div>
    </div>
  </div>
<!-- HEADER END -->

  <div class="relative pb-16 pt-4 bg-white overflow-hidden">
    <div class="relative px-4 sm:px-6 lg:px-8">
        <div class="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            <p class="mt-8 text-xl font-bold text-gray-900 leading-8">Nečitelná preparační hranice o otisku</p>
            <ul role="list">
                <li>Vemte nový otisk či sken, ideálně s použitím retrakčního vlákna.</li>
            </ul>

            <p class="mt-8 text-xl font-bold text-gray-900 leading-8">Protaženiny u otisku</p>
            <ul role="list">
                <li>Vemte nový otisk či sken</li>
            </ul>

            <p class="mt-8 text-xl font-bold text-gray-900 leading-8">Nedostatečný podklad pro registraci skusu</p>
            <ul role="list">
                <li>Použijte skusové šablony</li>
            </ul>

            <p class="mt-8 text-xl font-bold text-gray-900 leading-8">Příliš málo místa pro keramiku nebo pro nezbytnou minimální sílu stěny korunky či sílu spoje pro daný materiál</p>
            <ul role="list">
                <li>Dobrušte a vezměte nový otisk či sken</li>
            </ul>

            <p class="mt-8 text-xl font-bold text-gray-900 leading-8">Kontraindikace, zejména přemostění velkých úseků zirkonem, dens pendens u zirkonových konstrukcí či kombinace cementované a podmíněně snímatelné náhrady v jednom můstku</p>
            <ul role="list">
                <li>Zde je nedostatečná síla spojů - požadováno alespoň 12 mm3. Proto zhotovujeme pouze z kovu + fazetace keramikou</li>
            </ul>

            <p class="mt-8 text-xl font-bold text-gray-900 leading-8">Maryland můstek ze zirkonu</p>
            <ul role="list">
                <li>Zde je nedostatečná síla spojů - požadováno alespoň 12 mm3. Proto zhotovujeme pouze z kovu + fazetace keramikou</li>
            </ul>

        </div>
    </div>
  </div>
<SignupSection/>
<GalleryOverview/>
</template>
<script>
import SignupSection from '@/components/SignupSection.vue'
import GalleryOverview from '@/components/GalleryOverview.vue'

export default {
    components: {
      SignupSection,
      GalleryOverview,
        }
}
</script>