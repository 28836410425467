<template>
  <footer class="bg-white" aria-labelledby="footer-heading">
    <h2 id="footer-heading" class="sr-only">Footer</h2>
    <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      <div class="xl:grid xl:grid-cols-3 xl:gap-8">
        <div class="grid grid-cols-2 gap-8 xl:col-span-2">
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3
                class="
                  text-sm
                  font-semibold
                  text-gray-400
                  tracking-wider
                  uppercase
                "
              >
                Řešení
              </h3>
              <ul class="mt-4 space-y-4">
                <li v-for="item in navigation.solutions" :key="item.name">
                  <a
                    :href="item.href"
                    class="text-base text-gray-500 hover:text-gray-900"
                  >
                    {{ item.name }}
                  </a>
                </li>
              </ul>
            </div>
            <div class="mt-12 md:mt-0">
              <h3
                class="
                  text-sm
                  font-semibold
                  text-gray-400
                  tracking-wider
                  uppercase
                "
              >
                Podpora
              </h3>
              <ul class="mt-4 space-y-4">
                <li v-for="item in navigation.support" :key="item.name">
                  <a
                    :href="item.href"
                    class="text-base text-gray-500 hover:text-gray-900"
                  >
                    {{ item.name }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3
                class="
                  text-sm
                  font-semibold
                  text-gray-400
                  tracking-wider
                  uppercase
                "
              >
                O nás
              </h3>
              <ul class="mt-4 space-y-4">
                <li v-for="item in navigation.company" :key="item.name">
                  <a
                    :href="item.href"
                    class="text-base text-gray-500 hover:text-gray-900"
                  >
                    {{ item.name }}
                  </a>
                </li>
              </ul>
            </div>
            <div class="mt-12 md:mt-0">
              <h3
                class="
                  text-sm
                  font-semibold
                  text-gray-400
                  tracking-wider
                  uppercase
                "
              >
                PRAKTICKÉ
              </h3>
              <ul class="mt-4 space-y-4">
                <li v-for="item in navigation.legal" :key="item.name">
                  <a
                    :href="item.href"
                    class="text-base text-gray-500 hover:text-gray-900"
                  >
                    {{ item.name }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="mt-8 xl:mt-0">
          <h3
            class="text-sm font-semibold text-gray-400 tracking-wider uppercase"
          >
            Odebírejte náš newsletter
          </h3>
          <p class="mt-4 text-base text-gray-500">
            Neuniknou Vám žádné novinky a zajímavosti z oboru.
          </p>
          <form
            method="post"
            data-netlify="true"
            netlify-honeypot="bot-field"
            name="newsletter"
            class="mt-4 sm:flex sm:max-w-md"
          >
            <p class="hidden">
              <label
                >Don’t fill this out if you’re human: <input name="bot-field"
              /></label>
            </p>
            <input type="hidden" name="form-name" value="newsletter" />
            <label for="email-address" class="sr-only">Váš email</label>
            <input
              type="email"
              name="email-address"
              id="email-address"
              autocomplete="email"
              required=""
              class="
                appearance-none
                min-w-0
                w-full
                bg-white
                border border-gray-300
                shadow-sm
                py-2
                px-4
                text-base text-gray-900
                placeholder-gray-500
                focus:outline-none
                focus:ring-red-500
                focus:border-red-500
                focus:placeholder-gray-400
              "
              placeholder="Zadejte Váš email..."
            />
            <div class="mt-3 rounded-md sm:mt-0 sm:ml-3 sm:flex-shrink-0">
              <button
                type="submit"
                class="
                  uppercase
                  w-full
                  bg-red-600
                  flex
                  items-center
                  justify-center
                  border border-transparent
                  py-2
                  px-4
                  text-base
                  font-medium
                  text-white
                  hover:bg-red-700
                  focus:ring-2 focus:ring-offset-2 focus:ring-red-500
                "
              >
                Odeslat
              </button>
            </div>
          </form>
          <div class="flex mt-6 space-x-6 md:order-2">
            <a
              v-for="item in navigation.social"
              :key="item.name"
              :href="item.href"
              class="text-gray-400 hover:text-red-600"
            >
              <span class="sr-only">{{ item.name }}</span>
              <component :is="item.icon" class="h-12 w-12" aria-hidden="true" />
            </a>
          </div>
        </div>
      </div>
      <div
        class="
          mt-8
          border-t border-gray-200
          pt-8
          md:flex md:items-center md:justify-between
        "
      >
        <!-- <div class="flex space-x-6 md:order-2">
          <a v-for="item in navigation.social" :key="item.name" :href="item.href" class="text-gray-400 hover:text-gray-500">
            <span class="sr-only">{{ item.name }}</span>
            <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
          </a>
        </div> -->
        <p class="mt-8 text-base text-gray-400 md:mt-0 md:order-1">
          &copy; 2024 EUROZAHN s.r.o. Všechna práva vyhrazena.
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
// eslint-disable-next-line vue/multi-word-component-names
import { defineComponent, h } from 'vue'

const navigation = {
  solutions: [
    {
      name: 'Zubní náhrady',
      description:
        'Get a better understanding of where your traffic is coming from.',
      href: 'nahrady',
      // icon: ChartBarIcon,
    },
    {
      name: 'Výroba',
      description: 'Speak directly to your customers in a more meaningful way.',
      href: 'vyroba',
      // icon: CursorClickIcon,
    },
    {
      name: 'Ceník',
      description: "Connect with third-party tools that you're already using.",
      href: 'cenik',
      // icon: ViewGridIcon,
    },
    {
      name: 'Materiály',
      description: "Your customers' data will be safe and secure.",
      href: 'materialy',
      // icon: ShieldCheckIcon
    },
    {
      name: 'Blog',
      description: "Connect with third-party tools that you're already using.",
      href: 'https://blog.eurozahn.cz/',
      // icon: BookmarkAltIcon,
    },
    // {
    //   name: 'Ukázky prací',
    //   description: "Connect with third-party tools that you're already using.",
    //   href: 'kontakt',
    //   // icon: ViewGridIcon,
    // }
  ],
  support: [
    { name: 'Výběr a Indikace materiálu', href: 'materialy' },
    { name: 'Intraorální skenování', href: 'digitalni-otiskovani' },
    { name: 'Informace o zakázce', href: 'zakazky-informace' },
    { name: 'Fakturace & formality', href: 'fakturace' },
  ],
  company: [
    { name: 'Náš Team', href: 'nas-team' },
    { name: 'Volné Pozice', href: 'volne-pozice' },
    { name: 'Kontakt', href: 'kontakt' },
  ],
  legal: [
    { name: 'Obchodní a záruční podmínky', href: 'obchodni-podminky' },
    { name: 'Ochrana osobních údajů', href: 'gdpr' },
  ],
  social: [
    {
      name: 'Facebook',
      href: 'https://cs-cz.facebook.com/eurozahn',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d: 'M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z',
              'clip-rule': 'evenodd',
            }),
          ]),
      }),
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/eurozahn.laborator_cz/',
      icon: defineComponent({
        render: () =>
          h('svg', { fill: 'currentColor', viewBox: '0 0 24 24' }, [
            h('path', {
              'fill-rule': 'evenodd',
              d: 'M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z',
              'clip-rule': 'evenodd',
            }),
          ]),
      }),
    },
  ],
}

export default {
  name: 'Footer', // eslint-disable-line vue/no-reserved-component-names
  setup() {
    return {
      navigation,
    }
  },
}
</script>
