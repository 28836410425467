<template>
  <!-- <section class="bg-red-600">
    <div class="max-w-7xl mx-auto md:grid md:grid-cols-2 md:px-6 lg:px-8">
      <div class="py-12 px-4 sm:px-6 md:flex md:flex-col md:py-16 md:pl-0 md:pr-10 md:border-r md:border-red-900 lg:pr-16">
        <blockquote class="mt-6 md:flex-grow md:flex md:flex-col">
          <div class="relative text-lg font-medium text-gray-100 md:flex-grow">
            <svg class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-red-900" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
              <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
            </svg>
            <p class="relative">
              S laboratoří Eurozahn  spolupracuji asi rok a půl. Během této doby jsem si mohl ověřit, že se na jejich tým mohu spolehnout, a i když vždy nejde vše podle plánu, díky dobré komunikaci se vždy vše vyřeší včas. Bonusem jsou dostupnější ceny výrobků pro naše pacienty.   
            </p>
          </div>
          <footer class="mt-8">
            <div class="flex items-start">
              <div class="ml-4">
                <div class="text-base font-medium text-white">MDDr. Jan Kopřiva</div>
                <div class="text-base font-medium text-gray-200">Brno</div>
              </div>
            </div>
          </footer>
        </blockquote>
      </div>
      <div class="py-12 px-4 border-t-2 border-red-800 sm:px-6 md:py-16 md:pr-0 md:pl-10 md:border-t-0 md:border-l lg:pl-16">
        <blockquote class="mt-6 md:flex-grow md:flex md:flex-col">
          <div class="relative text-lg font-medium text-gray-100 md:flex-grow">
            <svg class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-red-900" fill="currentColor" viewBox="0 0 32 32">
              <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
            </svg>
            <p class="relative">
              S pracemi vaší laboratoře jsme spokojeni. Práce bývají přesné a esteticky pěkné. Spolupráce s dovozem a odvozem prací je skvělá, s řidičem je výborná komunikace, vždy vyjde vstříc našim potřebám. Stejně tak je dobrá domluva s laborantkami, se kterými občas potřebujeme doladit detaily práce.
            </p>
          </div>
          <footer class="mt-8">
            <div class="flex items-start">
              <div class="ml-4">
                <div class="text-base font-medium text-white">MUDr. Anatolij Pruskyj,</div>
                <div class="text-base font-medium text-gray-200">Znojmo</div>
              </div>
            </div>
          </footer>
        </blockquote>
      </div>
    </div>
  </section> -->

<!-- CAROUSEL -->
  <div class="bg-red-600">
  <Carousel :settings='settings' :breakpoints='breakpoints' :autoplay="3000">

    <Slide :key="0">
      <blockquote class="mt-6 md:flex-grow md:flex md:flex-col">
        <div class="relative text-lg font-medium text-gray-100 md:flex-grow">
          <svg class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-red-900" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
            <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
          </svg>
          <p class="relative">
            S laboratoří Eurozahn  spolupracuji asi rok a půl. Během této doby jsem si mohl ověřit, že se na jejich tým mohu spolehnout, a i když vždy nejde vše podle plánu, díky dobré komunikaci se vždy vše vyřeší včas. Bonusem jsou dostupnější ceny výrobků pro naše pacienty.   
          </p>
        </div>
        <footer class="mt-8">
          <div class="flex items-start">
            <!-- <div class="flex-shrink-0 inline-flex rounded-full border-2 border-white">
              <img class="h-12 w-12 rounded-full" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
            </div> -->
            <div class="ml-4">
              <div class="text-base font-medium text-white">MDDr. Jan Kopřiva</div>
              <div class="text-base font-medium text-gray-200">Brno</div>
            </div>
          </div>
        </footer>
      </blockquote>
    </Slide>

    <Slide :key="1">
      <blockquote class="mt-6 md:flex-grow md:flex md:flex-col">
        <div class="relative text-lg font-medium text-gray-100 md:flex-grow">
          <svg class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-red-900" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
            <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
          </svg>
          <p class="relative">
            S pracemi vaší laboratoře jsme spokojeni. Práce bývají přesné a esteticky pěkné. Spolupráce s dovozem a odvozem prací je skvělá, s řidičem je výborná komunikace, vždy vyjde vstříc našim potřebám. Stejně tak je dobrá domluva s laborantkami, se kterými občas potřebujeme doladit detaily práce.
          </p>
        </div>
        <footer class="mt-8">
          <div class="flex items-start">
            <!-- <div class="flex-shrink-0 inline-flex rounded-full border-2 border-white">
              <img class="h-12 w-12 rounded-full" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
            </div> -->
            <div class="ml-4">
              <div class="text-base font-medium text-white">MUDr. Anatolij Pruskyj,</div>
              <div class="text-base font-medium text-gray-200">Znojmo</div>
            </div>
          </div>
        </footer>
      </blockquote>
    </Slide>

    <Slide :key="2">
      <blockquote class="mt-6 md:flex-grow md:flex md:flex-col">
        <div class="relative text-lg font-medium text-gray-100 md:flex-grow">
          <svg class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-red-900" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
            <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
          </svg>
          <p class="relative">
            Se zubní laboratoří Eurozahn jsem velmi spokojen, a to nejen díky perfektní přesnosti prací, ale i díky rychlé komunikaci, která je v naší spolupráci klíčová.   
          </p>
        </div>
        <footer class="mt-8">
          <div class="flex items-start">
            <!-- <div class="flex-shrink-0 inline-flex rounded-full border-2 border-white">
              <img class="h-12 w-12 rounded-full" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
            </div> -->
            <div class="ml-4">
              <div class="text-base font-medium text-white">MDDr. Tomáš Slezák</div>
              <div class="text-base font-medium text-gray-200">RoDental s.r.o.</div>
            </div>
          </div>
        </footer>
      </blockquote>
    </Slide>

    <Slide :key="3">
      <blockquote class="mt-6 md:flex-grow md:flex md:flex-col">
        <div class="relative text-lg font-medium text-gray-100 md:flex-grow">
          <svg class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-red-900" fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
            <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
          </svg>
          <p class="relative">
            Skvělá spolupráce, výborná kvalita, přesná dodací lhůta, zároveň rozvoz zadarmo a velice příjemné ceny na protetické práce. Laborka pracuje s klasickými a digitálními otisky, což je velká výhoda. Můžu jen pochválit a doporučit.
          </p>
        </div>
        <footer class="mt-8">
          <div class="flex items-start">
            <!-- <div class="flex-shrink-0 inline-flex rounded-full border-2 border-white">
              <img class="h-12 w-12 rounded-full" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" />
            </div> -->
            <div class="ml-4">
              <div class="text-base font-medium text-white">Dr. Stanislav Nagreba</div>
              <div class="text-base font-medium text-gray-200">Pohořelice</div>
            </div>
          </div>
        </footer>
      </blockquote>
    </Slide>

      <template #addons>
        <Navigation />
      </template>
    </Carousel>
  </div>
<!--  -->

</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation } from 'vue3-carousel'

export default {
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data: () => ({
    // carousel settings
    settings: {
      itemsToShow: 1,
      snapAlign: 'center',
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      // 700px and up
      700: {
        itemsToShow: 1,
        snapAlign: 'center',
      },
      // 1024 and up
      1024: {
        itemsToShow: 2,
        snapAlign: 'start',
      },
    },
  }),
}
</script>

<style>
.carousel {
  @apply max-w-7xl mx-auto text-justify;
}

.carousel__slide {
  scroll-snap-stop: auto;
  @apply py-12 px-4 sm:px-6 md:py-16 md:pl-0 md:px-10 md:border-r md:border-red-900 lg:px-16;
}

.carousel__prev {
  @apply transform -translate-x-1/2 -translate-y-1/2 left-4 top-1/2;
}

.carousel__next {
  @apply transform translate-x-1/2 -translate-y-1/2 right-4 top-1/2;
}
</style>