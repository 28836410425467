<template>
  <div>
    <h1>Něco se nepovedlo, stránka nenalezena!</h1>
    <router-link to="/">
      <button>Zpět</button>
    </router-link>
  </div>
</template>

<script>
  export default {
    name: "SubmissionFail"
  }
</script>